import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  CardContent,
  TextField,
  Avatar,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";
import SideNavbar from "../../../components/sideNavbar";
import Header from "../../../components/header";

function Profile() {
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState("");
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.onboardingDashboard}/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setUserDetails(res.data?.data?.users);
    } catch (error) {
      setError("Failed to fetch user details. Please try again.");
    }
  };

  const updateProfile = async (data) => {
    try {
      await axios.put(
        `https://uae.justtawk.com/api/Onboarding/updateCutomerProfile/${uid}`,
        data,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getData();
    } catch (error) {
      setError("Failed to update user details. Please try again.");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails?.users_name || "",
      email: userDetails?.users_email || "",
      phoneNumber: userDetails?.users_mobile || "",
      companyName: userDetails?.users_business_name || "",
      industryType: userDetails?.users_industry || "",
      callCenterNumber: userDetails?.did_alias || "",
      address: userDetails?.users_company_address1 || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
      companyName: Yup.string().required("Company Name is required"),
      industryType: Yup.string().required("Industry Type is required"),
      callCenterNumber: Yup.string().required("Call Center Number is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      const data = {
        customerName: values.fullName,
        customerBusinessName: values.companyName,
        customerMobile: values.phoneNumber,
        customerEmail: values.email,
        customerIndutry: values.industryType,
        customerCompanyNumber: "",
        customerCompanyFax: "",
        customerCompanyAddress1: values.address,
        customerCompanyAddress2: "",
        customerBuilding: "",
        customerCompanyArea: "",
        customerCompanyCity: "",
      };
      updateProfile(data);
    },
  });

  return (
    <Box
      sx={{
        ml: { xs: "0px", md: "280px" },
      }}
    >
      <SideNavbar />
      <Box
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
        }}
      >
        <Header title={"Personal Details"} />
      </Box>
      <Box
        component="main"
        sx={{
          p: 3,
        }}
      >
        <Grid container spacing={3}>
          {/* Profile Card */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderRadius: 6,
                border: "1px solid #ededed",
              }}
            >
              <CardContent sx={{ textAlign: "center", py: 4 }}>
                <Avatar
                  src="/placeholder.svg?height=100&width=100"
                  sx={{
                    width: 100,
                    height: 100,
                    mx: "auto",
                    mb: 2,
                  }}
                />
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                  {userDetails?.users_name || "User Name"}
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 1 }}>
                  Did no: {userDetails?.did_alias || "Subscribe Now"}
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 2 }}>
                  {userDetails?.region || "INDIA"}
                </Typography>
              </CardContent>
            </Box>
          </Grid>

          {/* Profile Form */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                borderRadius: 6,
                border: "1px solid #ededed",
                p: 3,
              }}
            >
              <CardContent sx={{ p: 0 }}>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                  Profile
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 3 }}>
                  The information can be edited
                </Typography>
                <Box
                  sx={{
                    borderTop: "1px solid #EEEEEE",
                    mb: 3,
                  }}
                ></Box>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3}>
                    {[
                      { label: "Full Name", name: "fullName" },
                      { label: "Email Address", name: "email" },
                      { label: "Phone Number", name: "phoneNumber" },
                      { label: "Company Name", name: "companyName" },
                      { label: "Industry Type", name: "industryType" },
                      { label: "Call Center Number", name: "callCenterNumber" },
                      { label: "Address", name: "address" },
                    ].map((field) => (
                      <Grid item xs={12} sm={6} key={field.name}>
                        <TextField
                          label={field.label}
                          fullWidth
                          id={field.name}
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched[field.name] &&
                            Boolean(formik.errors[field.name])
                          }
                          helperText={
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Box sx={{ mt: 3, textAlign: "right" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        px: 4,
                        py: 1,
                        borderRadius: "100px",
                        bgcolor: "#6366f1",
                        textTransform: "capitalize",
                      }}
                    >
                      Save Details
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Profile;
