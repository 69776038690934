import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import AccordionFaq from "./AccordionFaq";
import AddFaqByImage from "./AddFaqByImage";
import SideNavbar from "../../../components/sideNavbar";
import Header from "../../../components/header";

function Faq() {
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          ml: { xs: "0px", md: "280px" },
        }}
      >
        <SideNavbar />
        <Box
          sx={{
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          <Header title={"Solution FAQ’s"} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <Box>
            <AccordionFaq />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Faq;
