import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  TextField,
  Button,
  CardContent,
} from "@mui/material";
import SideNav from "../../../agentComponents/sidenav";
import AgentCardsWidgets from "./CardsWidgets";
import AgentAccordionFaq from "./AgentFaq";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiEndPointsConfig from "../../../services/apiEndPoints";
import { useLocation } from "react-router-dom";

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function AgentDashboard() {
  const location = useLocation();

  const token = localStorage.getItem("token");
  const vendorNo = localStorage.getItem("vendorNo");

  const [userDetails, setUserDetails] = useState([]);
  const [faqDetails, setFaqDetails] = useState([]);

  const [connectedCall, setConnectedCall] = useState({
    leadID: "",
    vendorID: "",
    listID: "",
    phoneNumber: "",
    callDate: "",
  });

  // ?lead_id=15819&vendor_id=918982581288&list_id=34696805&gmt_offset_now=-5.00&phone_code=&phone_number=918401469312&title=&first_name=&middle_initial=&last_name=&address1=&address2=&address3=&city=&state=&province=&postal_code=&country_code=&gender=&date_of_birth=&alt_phone=&email=&security_phrase=21747001486&comments=%2B918401469312&user=555751003&pass=Rah@23&orig_pass=Rah@23&campaign=78944074&phone_login=555751003&original_phone_login=555751003&phone_pass=Rah@23&fronter=555751003&closer=555751003&group=21747001486&channel_group=21747001486&SQLdate=2025-03-30+115006&epoch=1743315607&uniqueid=1743315603.23&customer_zap_channel=SIP/419550out-00000005&customer_server_ip=10.180.67.44&server_ip=10.180.67.44&SIPexten=555751003&session_id=8600051&phone=918401469312&parked_by=15819&dispo=&dialed_number=918401469312&dialed_label=MAIN&source_id=VDCL&rank=0&owner=&camp_script=&in_script=&script_width=1047&script_height=503&fullname=rahul&agent_email=&recording_filename=&recording_id=&user_custom_one=&user_custom_two=&user_custom_three=&user_custom_four=&user_custom_five=&preset_number_a=&preset_number_b=&preset_number_c=&preset_number_d=&preset_number_e=&preset_dtmf_a=&preset_dtmf_b=&did_id=110&did_extension=918982581288&did_pattern=918982581288&did_description=&closecallid=4&xfercallid=0&agent_log_id=6820&entry_list_id=34696805&call_id=Y3301150040000015819&user_group=41739186&list_name=Triotech%20Default%20List&list_description=Triotech%20Default%20List&entry_date=2025-03-30+114748&did_custom_one=&did_custom_two=&did_custom_three=&did_custom_four=&did_custom_five=&called_count=1&email_row_id=&inOUT=IN&web_vars=&session_name=1743315443_55575100319674889&company_name=&commercial_name=&company_type=&function=&head_office=&departement=&activity=&company_reg_num=&employees_number=&share_capital=&naf_code=&number_employees=&website=&Injection=&creation_date=

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const leadIdFromUrl = params.get("lead_id");
    const vendorIdFromUrl = params.get("vendor_id");
    const listIdFromUrl = params.get("list_id");
    const phoneNumberFromUrl = params.get("phone_number");
    const callDateFromUrl = params.get("entry_date");
    setConnectedCall({
      leadID: leadIdFromUrl,
      vendorID: vendorIdFromUrl,
      listID: listIdFromUrl,
      phoneNumber: phoneNumberFromUrl,
      callDate: callDateFromUrl,
    });
    localStorage.setItem("vendorNo", vendorIdFromUrl);
  }, [location]);

  useEffect(() => {
    if (Object.values(connectedCall).some((val) => val)) {
      webHookData(connectedCall);
    }
  }, [connectedCall]);

  const webHookData = async (data) => {
    try {
      const res = await axios.post(
        `${apiEndPointsConfig.saveWebHookData}`,
        data,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
    } catch (error) {
      return error;
    }
  };

  const getDetailsByDidNo = async (phoneNumber) => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.searchByDID}/${phoneNumber}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status == true) {
        setFaqDetails(res.data.data);
        setUserDetails(res.data.users);
        localStorage.setItem("didNumber", phoneNumber);
      } else {
        setFaqDetails([]);
        setUserDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Formik setup for "Search by DID number"
  const formik = useFormik({
    initialValues: {
      didNumber: vendorNo,
    },
    // validationSchema: Yup.object({
    //   didNumber: Yup.string()
    //     .required("DID number is required")
    //     .matches(/^\d+$/, "Only numbers are allowed"),
    // }),
    onSubmit: (values) => {
      const data = {
        didNumber: values?.didNumber == "null" ? vendorNo : values?.didNumber,
      };
      console.log(data, "as");

      getDetailsByDidNo(data?.didNumber);
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        p: {
          xs: "50px 0 0 0",
          md: "50px 20px",
        },
      }}
    >
      <SideNav />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box sx={{ padding: "24px" }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: 900,
              color: "#424242",
              mt: "20px",
            }}
            gutterBottom
          >
            Agent Dashboard
          </Typography>
          {/* <AgentCardsWidgets />/ */}
          <Grid
            container
            sx={{
              mt: "20px",
            }}
          >
            <Grid item md={4} xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ padding: "0 0 24px0" }}>
                  <div style={{ marginTop: "20px", maxWidth: "400px" }}>
                    <div style={{ marginBottom: "16px" }}>
                      <Typography variant="body1" color="textSecondary">
                        Enter DID Number <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextField
                        id="didNumber"
                        name="didNumber"
                        placeholder="Enter DID Number"
                        variant="outlined"
                        fullWidth
                        disabled={vendorNo == "null" ? true : false}
                        value={vendorNo == "null" ? "" : vendorNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.didNumber &&
                          Boolean(formik.errors.didNumber)
                        }
                        helperText={
                          formik.touched.didNumber && formik.errors.didNumber
                        }
                        sx={{
                          padding: "8px",
                          marginTop: "8px",
                          borderRadius: "8px",
                          backgroundColor: "#f5f5f5",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            borderColor: "#465ff166",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          },
                        }}
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={vendorNo == "null" ? true : false}
                      fullWidth
                      sx={{
                        padding: "10px",
                        backgroundColor: "#1e1b4b",
                        "&:hover": {
                          backgroundColor: "#2e2a5b",
                        },
                      }}
                    >
                      Search
                    </Button>
                  </div>
                </Box>
              </form>
            </Grid>
            {userDetails.length == 0 ? (
              ""
            ) : (
              <Grid item md={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "12px",
                      p: {
                        xs: "0 15px 0 0",
                        md: "10px 15px 10px 0",
                      },
                    }}
                  >
                    <Box>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Full Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.userName}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Email
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.email}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Phone Number
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.Mobile}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Company Name
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.businessName}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Industry Type
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.Industry}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box
                              sx={{
                                p: "15px 20px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "grey",
                                }}
                              >
                                Your Call Center Number
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontWeight: 900,
                                }}
                              >
                                {userDetails?.did_number}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>{" "}
          <Box
            sx={{
              mt: "50px",
            }}
          >
            <AgentAccordionFaq faqDetails={faqDetails} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AgentDashboard;
