import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const MuiResponsiveTable = () => {
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const columnWidths = {
    Recording: "150px",
    Name: "200px",
    Date: "150px",
    Status: "120px",
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const res = await axios.get(
          `${apiEndPointsConfig.getCallLogs}/${uid}`,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const fetchedData = res.data?.data || [];
        setRowData(fetchedData);
        if (fetchedData.length) {
          setColumns(Object.keys(fetchedData[0]));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setRowData([]);
        setColumns([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTableData();
  }, [uid, token]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTableBody = () =>
    rowData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          {columns.map((column, colIndex) => (
            <TableCell
              key={colIndex}
              align="left"
              sx={{
                width: columnWidths[column] || "auto",
                whiteSpace: "nowrap",
              }}
            >
              {column === "Recording" && row[column] ? (
                <Tooltip title="Play Recording">
                  <IconButton
                    onClick={() => window.open(row[column], "_blank")}
                  >
                    <PlayCircleIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                row[column] || "-"
              )}
            </TableCell>
          ))}
        </TableRow>
      ));

  return (
    <Box>
      {loading ? (
        <Typography variant="h6" align="center">
          Loading Call Details, please wait...
        </Typography>
      ) : rowData.length ? (
        <Paper
          sx={{
            width: {
              xs: "100vw",
              md: "100%",
            },
            overflow: "scroll",
          }}
        >
          <Box sx={{ overflowX: "auto" }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align="left"
                        sx={{
                          width: columnWidths[column] || "auto",
                          fontWeight: "bold",
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            component="div"
            count={rowData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Paper>
      ) : (
        <Typography variant="h6" align="center">
          No Calls Available
        </Typography>
      )}
    </Box>
  );
};

export default MuiResponsiveTable;
