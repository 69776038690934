import { Box, Table, Typography } from "@mui/material";
import React from "react";
import TicketTable from "./TicketTable";
import SideNavbar from "../../components/sideNavbar";
import Header from "../../components/header";

const AgentTickets = () => {
  return (
    <>
      <Box
        sx={{
          ml: { xs: "0px", md: "280px" },
        }}
      >
        <SideNavbar />
        <Box
          sx={{
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          <Header title={"All Tickets"} />
        </Box>
        <Box
          sx={{
            // width: "100%",
            p: 3,
          }}
        >
          <TicketTable />
        </Box>
      </Box>
    </>
  );
};

export default AgentTickets;
