import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Avatar,
  Box,
  styled,
  Menu,
  MenuItem,
  Typography,
  Menu as MuiMenu,
} from "@mui/material";
import { Search, Person, NotificationsNone } from "@mui/icons-material";
import { useState } from "react";
import { BellRing, UserRoundPen, Lock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ProfileImg from "../../src/assets/user.png";

// Styled components
const StyledAppBar = styled(AppBar)({
  backgroundColor: "white",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  color: "#666",
});

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
  minHeight: "64px",
});

const NotificationDot = styled("span")({
  width: "6px",
  height: "6px",
  backgroundColor: "#EF9A9A",
  borderRadius: "50%",
  position: "absolute",
  top: "4px",
  right: "0px",
});

export default function Header({ title }) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };

  return (
    <Box
      position="static"
      sx={{
        border: "1px solid #EEEEEE",
      }}
    >
      <StyledToolbar>
        {/* Left side - Search */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 500,
            color: "#424242",
            // mb: 1,
          }}
        >
          {title}
        </Typography>

        {/* Right side - Icons and Avatar */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/* <IconButton color="inherit">
            <Person />
          </IconButton> */}
          <IconButton color="inherit" sx={{ position: "relative" }}>
            <BellRing style={{ color: "#BDBDBD" }} />
            <NotificationDot />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "0px 20px",
              width: "100%",
            }}
          >
            {/* Menu Icon */}
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleMenuClick}
            >
              {/* Profile Avatar */}
              <IconButton onClick={handleMenuClick}>
                <Avatar
                  src={ProfileImg}
                  alt="User Profile"
                  sx={{ width: 29, height: 29 }}
                />
              </IconButton>
            </IconButton>

            {/* Menu for Menu Icon */}
            <MuiMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  navigate("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  handleLogout();
                }}
              >
                Logout
              </MenuItem>
            </MuiMenu>
          </Box>
        </Box>
      </StyledToolbar>
    </Box>
  );
}
