import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../assets/Group.png";
import JusttawkBg from "../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../../src/assets/Justtawk-01.png";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const validationSchema = Yup.object({
  industryType: Yup.mixed().required("Industry type is required"), // Making industryType required
});

const industryOptions = [
  { label: "SaaS / Software Services", value: "saas" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Education", value: "education" },
  { label: "Ecommerce", value: "ecommerce" },
  { label: "Start-Up", value: "startup" },
  { label: "Travel", value: "travel" },
  { label: "Telecommunications", value: "telecommunications" },
  { label: "Others", value: "others" },
];

const SelectIndustryType = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const faqOption = localStorage.getItem("selected");

  const formik = useFormik({
    initialValues: {
      industryType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.setItem("industryType", values.industryType);
      addFormData(values);
    },
  });

  const addFormData = async (data) => {
    const access_token = token;
    try {
      const response = await axios.put(
        `${apiEndPointsConfig.companyDetails}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const industryType = formik.values.industryType;

      if (faqOption == "true") {
        if (industryType === "healthcare") {
          navigate("/healthCare");
        } else if (industryType === "saas") {
          navigate("/saas");
        } else if (industryType === "ecommerce") {
          navigate("/ECommerce");
        } else if (industryType === "education") {
          navigate("/Education");
        } else if (industryType === "startup") {
          navigate("/Startup");
        } else if (industryType === "telecommunications") {
          navigate("/Telecommunications");
        } else if (industryType === "travel") {
          navigate("/Travel");
        } else if (industryType === "others") {
          navigate("/other");
        } else {
          console.warn("Unknown industry type:", industryType);
        }
      } else if (faqOption == "false") {
        navigate("/manual-faq");
      } else {
        navigate("/manual-faq-by-ai");
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          height: {
            xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
            md: "100vh", // Full height on larger screens
          },
          backgroundImage: {
            xs: `url(${JusttawkBg})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center",
          position: "relative",
          color: "white",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
          mt: {
            xs: "-100px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 900,
              },
              fontSize: {
                xs: "1.1rem",
                md: "1.8rem",
              },
              mt: {
                xs: "50px",
                md: "0px",
              },
              whiteSpace: "nowrap",
            }}
          >
            Welcome to{" "}
            <Box
              sx={{
                display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                justifyContent: "center",
                alignItems: "center",
                p: "7px 0",
              }}
            >
              <img
                src={JusttawkLogo}
                alt="Justtawk Logo"
                style={{
                  height: "auto", // Keep aspect ratio intact
                  maxWidth: "100%", // Ensure the image does not exceed container width
                  width: "220px", // Set a fixed width for the image
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline" }, // Show text on md and larger
              }}
            >
              Justtawk
            </Box>
          </Typography>

          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 100,
              },
              fontSize: "1rem",
            }}
          >
            Your Gateway to Effortless Call Center.
          </Typography>
        </Box>
      </Grid>
      <Typography
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "center",
          p: "20px 0",
        }}
      >
        Step 1 - Business Details
      </Typography>
      <Grid
        container
        sx={{
          p: {
            xs: "0 20px",
            md: "0 0",
          },
        }}
      >
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Box
            sx={{
              p: "5px 10px",
            }}
          >
            <Box
              sx={{
                height: "8vh",
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <img src={Logo} alt="Logo" height="100%" width="200" />{" "}
            </Box>
          </Box>
          <Container maxWidth={"md"}>
            <Box
              sx={{
                padding: {
                  xs: "0px 0px 0 0px",
                  md: "50px 0px 0 0px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.6rem",
                    md: "2.5rem",
                  },
                  lineHeight: {
                    xs: "35px",
                    md: "60px",
                  },
                  fontWeight: 900,
                }}
              >
                Choose Your Industry
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.95rem",
                    md: "1.4rem",
                  },
                  fontWeight: 100,
                  lineHeight: {
                    xs: "18px",
                    md: "25px",
                  },
                  color: {
                    xs: "black",
                    md: "#6D6D6D",
                  },
                  mt: "15px",
                }}
              >
                Get industry-specific templates and insights to optimize your
                call center.
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "0px",
                    md: "60px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "none",
                      md: "initial",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        height: {
                          xs: "30vh",
                          md: "36vh",
                        },
                        overflow: "hidden",
                        paddingBottom: {
                          xs: "0px",
                          md: "50px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "100%",
                            p: "00px 0 100px 0",
                          },
                        }}
                      >
                        <Box>
                          <div
                            ref={(el) => (sections.current[0] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography>Select your industry type</Typography>
                            <Autocomplete
                              disableClearable={true}
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="filled"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                      ? formik.errors.industryType
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* ////// for mobile device */}
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "initial",
                      md: "none",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "50%",
                          },
                        }}
                      >
                        <Box>
                          {/* Company Name Step */}
                          <div
                            ref={(el) => (sections.current[2] = el)}
                            style={{ marginTop: "20px" }}
                          >
                            <Typography sx={{ color: "black" }}>
                              Select your industry type
                            </Typography>
                            <Autocomplete
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              // fullWidth
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="outlined"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                  }
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "black", // Black border color
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "black", // Keep black border on hover
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black", // Black border on focus
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    ml: {
                      xs: "0px",
                      md: "30px",
                    },
                    p: {
                      xs: "20px 0 50px 0",
                      md: "0 0 0px 0",
                    },
                    display: {
                      xs: "flex",
                      md: "initial",
                    },
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      mt: {
                        xs: "0px",
                        md: "91px",
                      },
                      ml: {
                        xs: "0px",
                        md: "0",
                      },
                      bgcolor: {
                        xs: "#3652E2",
                        md: "#2C75D3",
                      },
                    }}
                  >
                    Save & go next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectIndustryType;
