import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  TextField,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

// Validation schema
const validationSchema = Yup.object({
  DIDnumber: Yup.string().required("DID number is required"),
  Mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile must be a 10-digit number")
    .required("Mobile is required"),
  Email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  Name: Yup.string().required("Name is required"),
  Title: Yup.string().required("Title is required"),
  Issue: Yup.string().required("Issue description is required"),
  Category: Yup.string().required("Category is required"),
  Status: Yup.string().required("Status is required"),
  Assignee: Yup.string().required("Assignee is required"),
  Pincode: Yup.number()
    .typeError("Pincode must be a number")
    .required("Pincode is required"),
  Address: Yup.string().required("Address is required"),
});

// Drawer style
const drawerStyle = {
  width: "100%",
  maxWidth: 450,
  padding: 4,
};

const CreateTicket = ({ open, setOpen, getAllTicket }) => {
  // const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const agentId = localStorage.getItem("agentId");
  const searchNo = localStorage.getItem("searchNo");
  const didNumber = localStorage.getItem("didNumber");
  const callId = localStorage.getItem("callId");
  const token = localStorage.getItem("token");
  console.log(status, "status");

  useEffect(() => {
    getAllCategory();
    getAllStatus();
  }, []);

  const saveTicket = async (data) => {
    try {
      const res = await axios.post(`${apiEndPointsConfig.SaveTicket}`, data, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });

      setOpen(false);
      getAllTicket();
    } catch (error) {}
  };

  const getAllCategory = async () => {
    try {
      const res = await axios.get(`${apiEndPointsConfig.getCategory}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setCategories(res?.data?.data);
    } catch (error) {}
  };

  const getAllStatus = async () => {
    try {
      const res = await axios.get(`${apiEndPointsConfig.getStatus}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setStatus(res?.data?.data);
    } catch (error) {}
  };

  const initialValues = {
    DIDnumber: didNumber || "",
    Mobile: searchNo || "",
    Email: "",
    Name: "",
    Title: "",
    Issue: "",
    AgentID: agentId,
    Category: "",
    Status: "",
    Assignee: "",
    Pincode: "",
    Address: "",
    uuid: callId || "dummy",
  };

  const handleSubmit = (values) => {
    console.log("Form Submitted", values);
    saveTicket(values);
  };

  return (
    <>
      <Box>
        <Button
          sx={{
            textTransform: "capitalize",
            bgcolor: "darkblue",
            whiteSpace: "nowrap",
            p: "10px 30px",
            borderRadius: 6,
          }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Create Ticket
        </Button>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          width: "100%",
          maxWidth: 600,
        }}
      >
        <Box sx={drawerStyle}>
          <Typography
            variant="h6"
            sx={{
              p: "60px 0 20px 0",
              fontWeight: 700,
            }}
          >
            Create Ticket
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* DID Number */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="DID Number"
                      name="DIDnumber"
                      value={values.DIDnumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.DIDnumber && !!errors.DIDnumber}
                      helperText={touched.DIDnumber && errors.DIDnumber}
                    />
                  </Grid>
                  {/* Mobile */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Mobile"
                      name="Mobile"
                      value={values.Mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Mobile && !!errors.Mobile}
                      helperText={touched.Mobile && errors.Mobile}
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Email"
                      name="Email"
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Email && !!errors.Email}
                      helperText={touched.Email && errors.Email}
                    />
                  </Grid>
                  {/* Name */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Name"
                      name="Name"
                      value={values.Name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Name && !!errors.Name}
                      helperText={touched.Name && errors.Name}
                    />
                  </Grid>
                  {/* Title */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Title"
                      name="Title"
                      value={values.Title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Title && !!errors.Title}
                      helperText={touched.Title && errors.Title}
                    />
                  </Grid>
                  {/* Issue */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Issue"
                      name="Issue"
                      value={values.Issue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Issue && !!errors.Issue}
                      helperText={touched.Issue && errors.Issue}
                    />
                  </Grid>
                  {/* Category */}
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      options={categories}
                      getOptionLabel={(option) => option.name}
                      value={
                        categories.find(
                          (category) => category.id === values.Category
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "Category",
                            value: newValue ? newValue.id : "",
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Category"
                          error={touched.Category && !!errors.Category}
                          helperText={touched.Category && errors.Category}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              height: "40px",
                              borderRadius: "8px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Status */}
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      options={status}
                      getOptionLabel={(option) => option.name} // Assuming the status items have a "name" property
                      value={
                        status.find((option) => option.id === values.Status) ||
                        null // Assuming "values.Status" holds the status ID
                      }
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "Status",
                            value: newValue ? newValue.id : "", // Assuming "id" is the value for Status
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Status"
                          error={touched.Status && !!errors.Status}
                          helperText={touched.Status && errors.Status}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              height: "40px",
                              borderRadius: "8px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Assignee */}
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      options={["Agent", "Client"]}
                      getOptionLabel={(option) => option} // Map the options directly
                      value={values.Assignee || ""} // Current value of the field
                      onChange={(event, newValue) => {
                        handleChange({
                          target: { name: "Assignee", value: newValue || "" },
                        });
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Assignee"
                          name="Assignee"
                          fullWidth
                          error={touched.Assignee && !!errors.Assignee}
                          helperText={touched.Assignee && errors.Assignee}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              height: "40px",
                              borderRadius: "8px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Pincode */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Pincode"
                      name="Pincode"
                      value={values.Pincode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      error={touched.Pincode && !!errors.Pincode}
                      helperText={touched.Pincode && errors.Pincode}
                    />
                  </Grid>

                  {/* Address */}
                  <Grid item xs={12} md={12}>
                    <TextField
                      placeholder="Address"
                      name="Address"
                      value={values.Address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                        },
                      }}
                      multiline
                      error={touched.Address && !!errors.Address}
                      helperText={touched.Address && errors.Address}
                    />
                  </Grid>
                </Grid>
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Drawer>
    </>
  );
};

export default CreateTicket;
