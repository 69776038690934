import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material";
import TicketCards from "./Cards";
import { useNavigate } from "react-router-dom";
import apiEndPointsConfig from "../../services/apiEndPoints";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#9E9E9E", // Grey color for header background
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getStatusBgColor = (status) => {
  switch (status?.toLowerCase()) {
    case "open":
      return "green";
    case "in progress":
      return "orange";
    case "closed":
      return "red";
    case "waiting on customer":
      return "orange";
    default:
      return "blue";
  }
};

const TicketTable = () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [cardDetails, setCardDetails] = useState({});

  useEffect(() => {
    getAllTicket();
  }, []);

  const getAllTicket = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.getCustomerTicketByUser}/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(res?.data?.data?.TicketLists || []);
      setCardDetails(res?.data?.data || {});
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ pb: 3 }}>
        <Grid item xs={12} md={10}>
          <TicketCards cardDetails={cardDetails} />
        </Grid>
      </Grid>

      {data?.length === 0 ? (
        <Box sx={{ textAlign: "center", py: 5 }}>
          <Typography>No tickets were found</Typography>
        </Box>
      ) : isMobile ? (
        // Card View for Mobile Devices
        <Grid container spacing={2}>
          {data.map((row, index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  border: "1px solid #EEEEEE",
                  borderRadius: 4,
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Ticket ID:{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "darkblue",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/single-ticket/${row.TicketID}`)}
                    >
                      {row.TicketID}
                    </Box>
                  </Typography>
                  <Typography>User Name: {row.CustomerName}</Typography>
                  <Typography>Assignee: {row.Assignee}</Typography>
                  <Typography>Mobile: {row.Mobile}</Typography>
                  <Typography
                    sx={{
                      color: getStatusBgColor(row.Status),
                      fontWeight: "bold",
                    }}
                  >
                    Status: {row.Status}
                  </Typography>
                  <Typography>Ticket Type: {row.TicketType}</Typography>
                  <Typography>Create Date: {row.CreateDate}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Table View for Larger Screens
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid #EEEEEE",
            borderRadius: 4,
            overflowX: "auto",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Ticket ID</StyledTableCell>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Assignee</StyledTableCell>
                <StyledTableCell>Mobile</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Ticket Type</StyledTableCell>
                <StyledTableCell>Create Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    sx={{
                      color: "darkblue",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/single-ticket/${row.TicketID}`)}
                  >
                    {row.TicketID}
                  </StyledTableCell>
                  <StyledTableCell>{row.CustomerName}</StyledTableCell>
                  <StyledTableCell>{row.Assignee}</StyledTableCell>
                  <StyledTableCell>{row.Mobile}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: getStatusBgColor(row.Status),
                      fontWeight: "bold",
                    }}
                  >
                    {row.Status}
                  </StyledTableCell>
                  <StyledTableCell>{row.TicketType}</StyledTableCell>
                  <StyledTableCell>{row.CreateDate}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TicketTable;
