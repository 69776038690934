import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../assets/Group.png";
import JusttawkBg from "../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../../src/assets/Justtawk-01.png";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const validationSchema = Yup.object({
  companyName: Yup.string().required("Company name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  name: Yup.string()
    .matches(
      /^[a-zA-Z\s]{2,30}$/,
      "Name must be 2-30 characters and contain only letters and spaces"
    )
    .required("Name is required"),
});

const industryOptions = [
  { label: "SaaS / Software Services", value: "saas" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Education", value: "education" },
  { label: "Ecommerce", value: "ecommerce" },
  { label: "Start-Up", value: "startup" },
  { label: "Travel", value: "travel" },
  { label: "Telecommunications", value: "telecommunications" },
  { label: "Others", value: "others" },
];

const steps = [
  {
    title: "Hello! We’d love to understand your business better.",
    description:
      "To provide you with the best possible service, please share a few details about your business. This will help us tailor our support to your needs..",
    placeholder: "Enter countryName",
  },
  {
    title: "Hello! We’d love to understand your business better.",
    description:
      "To provide you with the best possible service, please share a few details about your business. This will help us tailor our support to your needs..",
    placeholder: "Enter countryName",
  },
  {
    title: "Hello! We’d love to understand your business better.",
    description:
      "To provide you with the best possible service, please share a few details about your business. This will help us tailor our support to your needs..",
    placeholder: "Enter countryName",
  },
];

const MultiStepForm = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);
  const [currentStep, setCurrentStep] = React.useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [disable, setDisable] = useState(false);

  const scrollToSection = (index) => {
    sections.current[index]?.scrollIntoView({ behavior: "smooth" });
  };
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      companyName: "",
      email: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values");
      addFormData(values);
    },
  });

  const addFormData = async (data) => {
    const access_token = token;
    try {
      const response = await axios.put(
        `${apiEndPointsConfig.companyDetails}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status == true) {
        navigate("/faq-selection");
      }

      console.log("Response:", response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleNext = async () => {
    const stepFields = {
      0: ["name"],
      1: ["companyName"],
      2: ["email"],
      3: ["dummy"],
      4: ["dummy"],
    };

    const currentFields = stepFields[currentStep];

    formik.setTouched(
      currentFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    );
    const errors = await formik.validateForm();
    if (currentStep == 2) {
      formik.submitForm();
    }
    const hasErrors = currentFields.some((field) => errors[field]);
    if (!hasErrors) {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => {
          const nextStep = prev + 1;
          scrollToSection(nextStep);
          return nextStep;
        });
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const prevStep = prev - 1;
        scrollToSection(prevStep);
        setActiveStep((prev) => prev - 1);
        return prevStep;
      });
    }
  };

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          height: {
            xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
            md: "100vh", // Full height on larger screens
          },
          backgroundImage: {
            xs: `url(${JusttawkBg})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: "cover", // Ensures the image covers the entire area
          backgroundPosition: "center",
          position: "relative",
          color: "white",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
          mt: {
            xs: "-100px",
            md: "0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 900,
              },
              fontSize: {
                xs: "1.1rem",
                md: "1.8rem",
              },
              mt: {
                xs: "50px",
                md: "0px",
              },
              whiteSpace: "nowrap",
            }}
          >
            Welcome to{" "}
            <Box
              sx={{
                display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                justifyContent: "center",
                alignItems: "center",
                p: "7px 0",
              }}
            >
              <img
                src={JusttawkLogo}
                alt="Justtawk Logo"
                style={{
                  height: "auto", // Keep aspect ratio intact
                  maxWidth: "100%", // Ensure the image does not exceed container width
                  width: "220px", // Set a fixed width for the image
                }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "inline" }, // Show text on md and larger
              }}
            >
              Justtawk
            </Box>
          </Typography>

          <Typography
            sx={{
              fontWeight: {
                xs: 400,
                md: 100,
              },
              fontSize: "1rem",
            }}
          >
            Your Gateway to Effortless Call Center.
          </Typography>
        </Box>
      </Grid>
      <Typography
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
          justifyContent: "center",
          p: "20px 0",
        }}
      >
        Step 1 - Business Details
      </Typography>
      <Grid
        container
        sx={{
          p: {
            xs: "0 20px",
            md: "0 0",
          },
        }}
      >
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Box
            sx={{
              p: "5px 10px",
            }}
          >
            <Box
              sx={{
                height: "8vh",
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <img src={Logo} alt="Logo" height="100%" width="200" />{" "}
            </Box>
          </Box>
          <Container maxWidth={"md"}>
            <Box
              sx={{
                padding: {
                  xs: "0px 0px 0 0px",
                  md: "70px 0px 0 0px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.6rem",
                    md: "2.2rem",
                  },
                  lineHeight: {
                    xs: "30px",
                    md: "40px",
                  },

                  fontWeight: 900,
                }}
              >
                {steps[currentStep].title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.95rem",
                    md: "1.2rem",
                  },
                  fontWeight: {
                    xs: 550,
                    md: 100,
                  },
                  lineHeight: {
                    xs: "18px",
                    md: "25px",
                  },
                  color: {
                    xs: "grey",
                    md: "#6D6D6D",
                  },
                  mt: "15px",
                }}
              >
                {steps[currentStep].description}
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "0px",
                    md: "50px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "none",
                      md: "initial",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        height: {
                          xs: "30vh",
                          md: "33vh",
                        },
                        overflow: "hidden",
                        paddingBottom: {
                          xs: "0px",
                          md: "50px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "100%",
                            p: "00px 0 100px 0",
                          },
                        }}
                      >
                        <animated.div style={fade}>
                          <div ref={(el) => (sections.current[0] = el)}>
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 0 ? "lightgrey" : "black",
                                opacity: currentStep !== 0 ? 0.5 : 1,
                              }}
                            >
                              Enter your name
                            </Typography>

                            <TextField
                              id="name"
                              name="name"
                              variant="filled"
                              margin="normal"
                              fullWidth
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={currentStep !== 0}
                              error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                              }
                              helperText={
                                formik.touched.name && formik.errors.name
                                  ? formik.errors.name
                                  : ""
                              }
                              sx={{
                                opacity: currentStep !== 0 ? 0.3 : 1,
                              }}
                            />
                          </div>
                          <div
                            ref={(el) => (sections.current[1] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 1 ? "lightgrey" : "black",
                                opacity: currentStep !== 1 ? 0.5 : 1,
                              }}
                            >
                              Enter your company name
                            </Typography>
                            <TextField
                              sx={{
                                opacity: currentStep !== 1 ? 0.3 : 1,
                              }}
                              name="companyName"
                              id="companyName"
                              value={formik.values.companyName}
                              onChange={formik.handleChange}
                              fullWidth
                              variant="filled"
                              margin="normal"
                              disabled={currentStep !== 1}
                              error={
                                formik.touched.companyName &&
                                Boolean(formik.errors.companyName)
                              }
                              helperText={
                                formik.touched.companyName &&
                                formik.errors.companyName
                                  ? formik.errors.companyName
                                  : ""
                              }
                            />
                          </div>

                          {/* <div
                            ref={(el) => (sections.current[2] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 2 ? "lightgrey" : "black",
                                opacity: currentStep !== 2 ? 0.5 : 1,
                              }}
                            >
                              Select your industry type
                            </Typography>
                            <Autocomplete
                              sx={{
                                opacity: currentStep !== 2 ? 0.3 : 1,
                              }}
                              disableClearable={true}
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              fullWidth
                              disabled={currentStep !== 2} // Enable only the current step
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="filled"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                      ? formik.errors.industryType
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div> */}
                          <div
                            ref={(el) => (sections.current[2] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 2 ? "lightgrey" : "black",
                                opacity: currentStep !== 2 ? 0.5 : 1,
                              }}
                            >
                              Enter your email address
                            </Typography>
                            <TextField
                              type="email"
                              id="email"
                              name="email"
                              variant="filled"
                              margin="normal"
                              fullWidth
                              disabled={currentStep !== 2} // Enable only the current step
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                              }
                              helperText={
                                formik.touched.email && formik.errors.email
                                  ? formik.errors.email
                                  : ""
                              }
                              sx={{
                                opacity: currentStep !== 2 ? 0.3 : 1,
                              }}
                            />
                          </div>

                          <div
                            ref={(el) => (sections.current[4] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 4 ? "lightgrey" : "black",
                                opacity: currentStep !== 4 ? 0 : 0,
                              }}
                            >
                              Select your industry type
                            </Typography>
                            <Autocomplete
                              sx={{
                                opacity: currentStep !== 4 ? 0 : 0,
                              }}
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              fullWidth
                              disabled={currentStep !== 3} // Enable only the current step
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                      ? formik.errors.industryType
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                        </animated.div>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* ////// for mobile device */}
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "initial",
                      md: "none",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "50%",
                          },
                        }}
                      >
                        <animated.div style={fade}>
                          {/* countryName Step */}
                          {currentStep === 0 && (
                            <div
                              ref={(el) => (sections.current[0] = el)}
                              style={{ marginTop: "20px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Enter your name
                              </Typography>
                              <TextField
                                name="name"
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={
                                  formik.touched.name &&
                                  Boolean(formik.errors.name)
                                }
                                helperText={
                                  formik.touched.name && formik.errors.name
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black", // Black border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "black", // Keep black border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black", // Black border on focus
                                    },
                                  },
                                }}
                              />
                            </div>
                          )}

                          {/* Name Step */}
                          {currentStep === 1 && (
                            <div
                              ref={(el) => (sections.current[1] = el)}
                              style={{ marginTop: "20px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Enter your company name
                              </Typography>
                              <TextField
                                name="companyName"
                                id="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={
                                  formik.touched.companyName &&
                                  Boolean(formik.errors.companyName)
                                }
                                helperText={
                                  formik.touched.companyName &&
                                  formik.errors.companyName
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black", // Black border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "black", // Keep black border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black", // Black border on focus
                                    },
                                  },
                                }}
                              />
                            </div>
                          )}

                          {/* Company Name Step */}
                          {currentStep === 2 && (
                            <div
                              ref={(el) => (sections.current[2] = el)}
                              style={{ marginTop: "20px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Enter your email address
                              </Typography>
                              <TextField
                                id="email"
                                name="email"
                                type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={
                                  formik.touched.email &&
                                  Boolean(formik.errors.email)
                                }
                                helperText={
                                  formik.touched.email && formik.errors.email
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "black", // Black border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "black", // Keep black border on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "black", // Black border on focus
                                    },
                                  },
                                }}
                              />
                            </div>
                          )}
                        </animated.div>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    ml: {
                      xs: "0px",
                      md: "30px",
                    },
                    p: {
                      xs: "20px 0 50px 0",
                      md: "0 0 0px 0",
                    },
                    display: {
                      xs: "flex",
                      md: "initial",
                    },
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    color="primary"
                    onClick={handleBack}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {/* <img src={TopArrow} /> */}

                    {currentStep == 0 ? (
                      <ArrowUpwardIcon
                        sx={{
                          fontSize: "2.5rem",
                          color: "lightgrey",
                          fontWeight: 100,
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        sx={{
                          fontSize: "2.5rem",
                          color: "#2C75D3",
                          fontWeight: 100,
                        }}
                      />
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    // type="submit"
                    onClick={() => {
                      handleNext();
                    }}
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      mt: {
                        xs: "0px",
                        md: "15px",
                      },
                      ml: {
                        xs: "30px",
                        md: "0",
                      },
                      bgcolor: {
                        xs: "#3652E2",
                        md: "#2C75D3",
                      },
                    }}
                  >
                    {currentStep < 2 ? "Next" : "Save & Next"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
            display: {
              xs: "none",
              md: "flex",
            },
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiStepForm;
