import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { ShoppingBag, ShoppingCart } from "@mui/icons-material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

export default function TicketCards({ cardDetails }) {
  console.log(cardDetails, "cardDetails");

  const [timeFrame1, setTimeFrame1] = React.useState("This Week");
  const [timeFrame2, setTimeFrame2] = React.useState("This Week");

  const timeFrameOptions = ["This Week", "Last Week", "This Month"];

  const StatBox = ({ icon, timeFrame, setTimeFrame, children }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        bgcolor: "background.default",
        borderRadius: 2,
        flex: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <IconButton
          sx={{
            bgcolor:
              icon === ShoppingBag
                ? "rgb(255, 244, 229)"
                : "rgb(232, 244, 253)",
            borderRadius: 2,
            "&:hover": {
              bgcolor:
                icon === ShoppingBag
                  ? "rgb(255, 244, 229)"
                  : "rgb(232, 244, 253)",
            },
          }}
        >
          {React.createElement(icon, {
            sx: {
              color:
                icon === ShoppingBag
                  ? "rgb(255, 167, 38)"
                  : "rgb(33, 150, 243)",
            },
          })}
        </IconButton>
        {/* <Autocomplete
          value={timeFrame}
          onChange={(event, newValue) => setTimeFrame(newValue)}
          options={timeFrameOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              sx={{
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
              }}
            />
          )}
          sx={{
            width: 150,
            "& .MuiAutocomplete-popupIndicator": { display: "none" },
            color:"grey"
          }}
        /> */}
      </Box>

      {children}
    </Paper>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3.5}>
        <Grid
          container
          spacing={0}
          sx={{
            bgcolor: "white",
            borderRadius: 6,
            p: "30px",
            border: "1px solid lightgrey",
          }}
        >
          <Grid item xs={4} md={4}>
            <Typography color="#8B8D97" variant="body2">
              All Ticket
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "#45464E" }}>
              {cardDetails?.status?.Total || 0}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography color="#8B8D97" variant="body2">
              Pending
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "#45464E" }}>
              {cardDetails?.status?.Pending || 0}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography color="#8B8D97" variant="body2">
              Completed
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "#45464E" }}>
              {cardDetails?.status?.Completed || 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={2.5}>
        <Grid
          container
          spacing={0}
          sx={{
            bgcolor: "white",
            borderRadius: 6,
            p: "30px",
            border: "1px solid lightgrey",
          }}
        >
          <Grid item xs={6} md={6}>
            <Typography color="#8B8D97" variant="body2">
              Agent
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "#45464E" }}>
              {cardDetails?.assignee?.Agents || 0}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography color="#8B8D97" variant="body2">
              Client
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "#45464E" }}>
              {cardDetails?.assignee?.Client || 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
