import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import JusttawkLogo from "../../../../src/assets/Justtawk-01.png";
import LoginBg from "../../../../src/assets/LoginBg.jpeg";
import JusttawkBg from "../../../../src/assets/justtawk-bg.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

export default function Login() {
  const navigate = useNavigate();
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    addIpAddress();
  }, []);

  const addIpAddress = async () => {
    const res = await axios.get(`https://api64.ipify.org/?format=json`);
    setIpAddress(res.data.ip);
  };

  const registerUser = async (value) => {
    const data = {
      mobile: value,
      ip_address: ipAddress,
    };
    try {
      const response = await axios.post(apiEndPointsConfig.addLogin, data);
      localStorage.setItem("token", response?.data?.access_token);
      localStorage.setItem("uid", response?.data?.uid);
      localStorage.setItem("isNew", response?.data?.isNew);
      localStorage.setItem("mobileNo", value);
      if (response.data.status == true) {
        navigate("/otp");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const location = useLocation();

  const getQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  useEffect(() => {
    const utm_source = getQueryParam("utm_source");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_medium = getQueryParam("utm_medium");

    if (utm_source) {
      localStorage.setItem("source", utm_source);
    }
    if (utm_medium) {
      localStorage.setItem("medium", utm_medium);
    }
    if (utm_campaign) {
      localStorage.setItem("campaign", utm_campaign);
    }
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number"),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
      registerUser(values.mobile);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            mt: 0,
            height: {
              xs: "50vh",
              md: "100vh",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              height: {
                xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
                md: "100vh", // Full height on larger screens
              },
              backgroundImage: {
                xs: `url(${JusttawkBg})`,
                md: `url(${LoginBg})`,
              },
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) => t.palette.primary.main,
              backgroundSize: "cover", // Ensures the image covers the entire area
              backgroundPosition: "center",
              position: "relative",
              color: "white",
              display: { xs: "flex", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
              mt: {
                xs: "-100px",
                md: "0px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 900,
                  },
                  fontSize: {
                    xs: "1.1rem",
                    md: "1.8rem",
                  },
                  mt: {
                    xs: "50px",
                    md: "0px",
                  },
                  whiteSpace: "nowrap",
                }}
              >
                Welcome to{" "}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                    justifyContent: "center",
                    alignItems: "center",
                    p: "7px 0",
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "220px", // Set a fixed width for the image
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "inline" }, // Show text on md and larger
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "150px", // Set a fixed width for the image
                    }}
                  />
                </Box>
              </Typography>

              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 100,
                  },
                  fontSize: "1rem",
                  p: {
                    xs: "0 20px",
                    md: "0 50px",
                  },
                }}
              >
                Affordable call center solution that helps your business by
                seamlessly receiving and managing customer calls.
              </Typography>
            </Box>

            {/* Bottom Text Block */}
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexDirection: "column",
                justifyContent: "center", // Center text vertically
                alignItems: "center", // Center text horizontally
                textAlign: "center", // Ensure text is centered within its Box
                mt: "auto", // Pushes the bottom block towards the bottom
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  fontSize: {
                    xs: "1rem",
                    md: "1.8rem",
                  },
                }}
              >
                Your business, our voice.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 100,
                  fontSize: {
                    xs: "0.7rem",
                    md: "1rem",
                  },
                }}
              >
                Real-time call monitoring and 24/7 support for <br /> seamless
                customer service.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{
              p: { xs: "0 40px", md: "0 50px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              maxWidth: "500px",
              mx: "auto",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: 400,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.6rem",
                      md: "2rem",
                    },
                    fontWeight: 900,
                    mt: {
                      xs: "30px",
                      md: "0px",
                    },
                  }}
                >
                  Hey! Good Choice.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    display: {
                      xs: "none",
                      md: "initial",
                    },
                  }}
                >
                  it's free. No credit card needed.
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  color: "#26203B",
                  mt: {
                    xs: 2,
                    md: 4,
                  },
                }}
              >
                Please enter your mobile number*
              </Typography>
              <TextField
                id="mobile"
                name="mobile"
                type="tel"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
                InputProps={{
                  style: {
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #465FF166",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  },
                }}
              />
              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Mobile Number Requirements
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  • Must be a valid 10-digit mobile number
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  • Cannot contain any letters or special characters (e.g., +,
                  -, or spaces)
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: "#3652E2",
                  textTransform: "capitalize",
                }}
              >
                Get Started
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
