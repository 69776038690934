import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Activity, Minimize, PhoneCall, PieChart, Tickets } from "lucide-react";
import { useNavigate } from "react-router-dom";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import EventSeatIcon from "@mui/icons-material/EventSeat";
ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerQueries = ({ data }) => {
  const [chartLoader, setChartLoader] = useState(true);

  useEffect(() => {
    setChartLoader(false);
  }, []);

  const navigate = useNavigate();

  const doughnutData = {
    labels: data.ticketType?.categories,
    datasets: [
      {
        data: data.ticketType?.value,
        backgroundColor: [
          "#6366F1", // Blue Violet
          "#14B8A6", // Teal
          "#FB9C0C", // Orange
          "#EF4444", // Red
          "#22C55E", // Green
          "#3B82F6", // Blue
          "#A855F7", // Purple
        ],
        hoverBackgroundColor: [
          "#6366F1", // Blue Violet
          "#14B8A6", // Teal
          "#FB9C0C", // Orange
          "#EF4444", // Red
          "#22C55E", // Green
          "#3B82F6", // Blue
          "#A855F7", // Purple
        ],
        borderWidth: 2,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    cutout: "50%",
    plugins: {
      legend: {
        position: "right",
      },
    },
    maintainAspectRatio: false,
  };

  const cardStyle = {
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    padding: 3,
  };

  const getStatusBgColor = (status) => {
    switch (status?.toLowerCase()) {
      case "open":
        return "green";
      case "in progress":
        return "orange";
      case "closed":
        return "red";
      case "waiting":
        return "orange";
      case "on-hold":
        return "black";
      default:
        return "blue";
    }
  };

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date";
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <Box sx={{ padding: 0, mt: 2 }}>
      <Grid container spacing={2}>
        {/* Chart Section */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              ...cardStyle,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {data?.ticketType?.length == 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: {
                    xs: 100,
                    md: 290,
                  },
                }}
              >
                <Typography>No ticket Type available. </Typography>
              </Box>
            ) : (
              <>
                {" "}
                <Typography sx={{ padding: "15px 0", color: "grey" }}>
                  Ticket Type
                </Typography>
                <Box sx={{ width: "100%", height: 250 }}>
                  <Doughnut data={doughnutData} options={doughnutOptions} />
                </Box>
              </>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              {/* Assignee Section */}
              <Box
                sx={{
                  borderRadius: 6,
                  border: "1px solid #EEEEEE",
                  p: 3,
                  bgcolor: "#6366f1",
                }}
              >
                {/* Header */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 4,
                  }}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <InterpreterModeIcon sx={{ color: "white" }} />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: "white", flexGrow: 1 }}
                  >
                    Net Customer Queries
                  </Typography>
                </Box>

                {/* Metrics */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 3,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        mb: 1,
                        fontSize: "small",
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      Previous Month
                    </Typography>
                    <Typography sx={{ fontWeight: "small", color: "white" }}>
                      {data?.netMonths?.previousMonth}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1, fontSize: "small", color: "white" }}
                    >
                      This Month
                    </Typography>
                    <Typography sx={{ fontWeight: "small", color: "white" }}>
                      {" "}
                      {data?.netMonths?.thisMonth}
                    </Typography>
                  </Box>
                  {/* <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  Gain %
                </Typography>
                <Typography sx={{ fontWeight: "small" }}>-50% / 50%</Typography>
              </Box> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  borderRadius: 6,
                  border: "1px solid #EEEEEE",
                  p: 3,
                  mt: 2,
                }}
              >
                {/* Header */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <Tickets size={20} style={{ color: "#6366f1" }} />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: "black", flexGrow: 1 }}
                  >
                    Ticket Details
                  </Typography>
                </Box>

                {/* Metrics */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 3,
                  }}
                >
                  <Box>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1, fontSize: "small" }}
                    >
                      All Tickets
                    </Typography>
                    <Typography sx={{ fontWeight: "small" }}>
                      {" "}
                      {data?.ticketAssigneeStatus?.Total}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1, fontSize: "small" }}
                    >
                      Pending
                    </Typography>
                    <Typography sx={{ fontWeight: "small" }}>
                      {data?.ticketAssigneeStatus?.Agents}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1, fontSize: "small" }}
                    >
                      Resolved
                    </Typography>
                    <Typography sx={{ fontWeight: "small" }}>
                      {data?.ticketAssigneeStatus?.Client}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} overflow={"hidden"}>
          <Box
            sx={{
              border: "1px solid #EEEEEE",
              borderRadius: 6,
              padding: 2,
              overflow: "auto",
              height: 322,
            }}
          >
            {data?.ticketDetail?.length == 0 ? (
              ""
            ) : (
              <Typography>Ticket History</Typography>
            )}

            <Box mt={2}>
              {data?.ticketDetail?.length == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: {
                      xs: 100,
                      md: 250,
                    },
                  }}
                >
                  <Typography>No ticket details available. </Typography>
                </Box>
              ) : (
                <>
                  {data?.ticketDetail?.map((e, i) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box
                        onClick={() => navigate(`/single-ticket/${e.TicketId}`)}
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: 2,
                            // bgcolor: "#f0f0ff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                          }}
                        >
                          <PhoneCall style={{ color: "#2b8aff" }} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "small",
                            }}
                          >
                            {e?.CustomerName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "small",
                              color: "grey",
                            }}
                          >
                            {e?.Mobile}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "grey",
                            fontSize: "small",
                          }}
                        >
                          {formatDate(e.CreateDate)}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "small",
                            color: getStatusBgColor(e.Status),
                          }}
                        >
                          {e.Status}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerQueries;
