import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../../assets/Group.png";
import lightbulb from "../../../../assets/lightbulb.png";
import yelloBulb from "../../../../assets/lightbulb1.png";
import axios from "axios";
import apiEndPointsConfig from "../../../../services/apiEndPoints";

const validationSchema = Yup.object({
  summary: Yup.mixed().required("field is required"), // Making companyName required
});

const ManualFaqByAiMobile = ({ nextStep }) => {
  const navigate = useNavigate();

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const industryType = localStorage.getItem("industryType");

  const addFaqDetails = async (data) => {
    const access_token = token;
    try {
      const response = await axios.put(
        `${apiEndPointsConfig.addFaqByAi}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/add-manual-faq-by-ai");
      localStorage.removeItem("industryType");
      console.log("Response:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      summary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        summary: values.summary,
        industryType: industryType,
      };
      console.log(data);
      addFaqDetails(data);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid item md={9.5} xs={12}>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "0px",
                    md: "100px",
                  },
                  p: "0 20px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{ display: { xs: "initial", md: "none" } }}
                >
                  <Box>
                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "100%", md: "50%" },
                        }}
                      >
                        {/* Step 1 */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            name="summary"
                            id="summary"
                            variant="outlined"
                            margin="normal"
                            label="Enter company details (timings, industry, fieldwork, etc.)"
                            fullWidth
                            rows={3}
                            multiline
                            value={formik.values.summary}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.summary &&
                              Boolean(formik.errors.summary)
                            }
                            helperText={
                              formik.touched.summary && formik.errors.summary
                                ? formik.errors.summary
                                : ""
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    ml: {
                      xs: "0px",
                      md: "30px",
                    },
                    p: {
                      xs: "20px 0 50px 0",
                      md: "0 0 0px 0",
                    },
                    display: {
                      xs: "flex",
                      md: "initial",
                    },
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      mt: {
                        xs: "0px",
                        md: "15px",
                      },
                      ml: {
                        xs: "30px",
                        md: "0",
                      },
                    }}
                  >
                    Save & go next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualFaqByAiMobile;
