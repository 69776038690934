import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import MainForm from "../view/form";
import Number from "../view/form/section/Number";
import Loading from "../view/form/section/Loading";
import Qr from "../view/form/section/Qr";
import Faq from "../view/user/faq";
import DashBoard from "../view/user/dashboard";
import MultiStepForm from "../view/form/section/MultiStepForm";
import Login from "../view/user/login/Login";
import Otp from "../view/user/login/Otp";
import SaaS from "../view/form/faq/saas/Saas";
import PricingPlans from "../view/user/pricing";
import ECommerce from "../view/form/faq/ECommerce/ECommerce";
import Education from "../view/form/faq/Education/Education";
import HealthCare from "../view/form/faq/HealthCare/HealthCare";
import Software from "../view/form/faq/Software/Software";
import Startup from "../view/form/faq/Startup/Startup";
import Telecommunications from "../view/form/faq/Telecommunications/Telecommunications";
import Travel from "../view/form/faq/Travel/Travel";
import CallLogs from "../view/user/callLogs";
import ProtectedRoute from "./ProtectedRoute";
import Other from "../view/form/faq/Others/Other";
import BillDetails from "../view/user/billDetails/BillDetails";
import CheckOut from "../view/user/checkout";
import ChashfreeRes from "../view/user/checkout/ChashfreeRes";
import Profile from "../view/user/profile";
import OptionFaq from "../view/form/section/OptionFaq";
import SelectIndustryType from "../view/form/section/SelectIndustryType";
import ManualFaq from "../view/form/faq/ManualFaq/ManualFaq";
import AgentLogin from "../view/agent/login/Login";
import AgentDashBoard from "../view/agent/dashboard";
import AgentTickets from "../view/agent/tickets";
import TicketTable from "../view/tickets/index.js";
import SingleTicket from "../view/tickets/singleTicket/SingleTicket.js";
import GetStarted from "../view/campaign/login/GetStarted.js";
import CampaignOptionFaq from "../view/campaign/form/section/OptionFaq.js";
import CampaignSelectIndustryType from "../view/campaign/form/section/SelectIndustryType.js";
import CampaignManualFaq from "../view/campaign/form/faq/ManualFaq/ManualFaq.js";
import CampaignECommerce from "../view/campaign/form/faq/ECommerce/ECommerce.js";
import CampaignEducation from "../view/campaign/form/faq/Education/Education.js";
import CampaignHealthCare from "../view/campaign/form/faq/HealthCare/HealthCare.js";
import CampaignOther from "../view/campaign/form/faq/Others/Other.js";
import CampaignSaaS from "../view/campaign/form/faq/saas/Saas.js";
import CampaignSoftware from "../view/campaign/form/faq/Software/Software.js";
import CampaignStartup from "../view/campaign/form/faq/Startup/Startup.js";
import CampaignTelecommunications from "../view/campaign/form/faq/Telecommunications/Telecommunications.js";
import CampaignTravel from "../view/campaign/form/faq/Travel/Travel.js";
import CampaignNumber from "../view/campaign/form/section/Number.js";
import SIngleTicket from "../view/agent/tickets/singleTicket/SIngleTicket.js";
import SingleTicketCard from "../view/tickets/singleTicket/SingleTicketCard.js";
import ManualFaqByAi from "../view/form/faq/ManualFaqByAi/ManualFaqByAi.js";
import ShowFaqByAi from "../view/form/faq/ManualFaqByAi/addFaq/ShowFaqByAi.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/get-started",
    element: <GetStarted />,
  },
  {
    path: "/form",
    element: <ProtectedRoute element={<MultiStepForm />} />,
  },
  {
    path: "/campaign/number",
    element: <ProtectedRoute element={<CampaignNumber />} />,
  },
  {
    path: "/number",
    element: <ProtectedRoute element={<Number />} />,
  },
  {
    path: "/loading",
    element: <ProtectedRoute element={<Loading />} />,
  },
  {
    path: "/faq-selection",
    element: <ProtectedRoute element={<OptionFaq />} />,
  },
  {
    path: "/campaign/faq-selection",
    element: <ProtectedRoute element={<CampaignOptionFaq />} />,
  },
  {
    path: "/campaign/select-industry-type",
    element: <ProtectedRoute element={<CampaignSelectIndustryType />} />,
  },
  {
    path: "/select-industry-type",
    element: <ProtectedRoute element={<SelectIndustryType />} />,
  },
  {
    path: "/campaign/manual-faq",
    element: <ProtectedRoute element={<CampaignManualFaq />} />,
  },
  {
    path: "/manual-faq",
    element: <ProtectedRoute element={<ManualFaq />} />,
  },
  {
    path: "/manual-faq-by-ai",
    element: <ProtectedRoute element={<ManualFaqByAi />} />,
  },
  {
    path: "/add-manual-faq-by-ai",
    element: <ProtectedRoute element={<ShowFaqByAi />} />,
  },
  {
    path: "/campaign/saas",
    element: <ProtectedRoute element={<CampaignSaaS />} />,
  },
  {
    path: "/saas",
    element: <ProtectedRoute element={<SaaS />} />,
  },
  {
    path: "/campaign/ECommerce",
    element: <ProtectedRoute element={<CampaignECommerce />} />,
  },

  {
    path: "/ECommerce",
    element: <ProtectedRoute element={<ECommerce />} />,
  },
  {
    path: "/campaign/Education",
    element: <ProtectedRoute element={<CampaignEducation />} />,
  },
  {
    path: "/Education",
    element: <ProtectedRoute element={<Education />} />,
  },
  {
    path: "/campaign/HealthCare",
    element: <ProtectedRoute element={<CampaignHealthCare />} />,
  },
  {
    path: "/campaign/other",
    element: <ProtectedRoute element={<CampaignOther />} />,
  },
  {
    path: "/HealthCare",
    element: <ProtectedRoute element={<HealthCare />} />,
  },
  {
    path: "/campaign/software",
    element: <ProtectedRoute element={<CampaignSoftware />} />,
  },
  {
    path: "/Software",
    element: <ProtectedRoute element={<Software />} />,
  },
  {
    path: "/campaign/startup",
    element: <ProtectedRoute element={<CampaignStartup />} />,
  },
  {
    path: "/Startup",
    element: <ProtectedRoute element={<Startup />} />,
  },
  {
    path: "/campaign/telecommunications",
    element: <ProtectedRoute element={<CampaignTelecommunications />} />,
  },
  {
    path: "/Telecommunications",
    element: <ProtectedRoute element={<Telecommunications />} />,
  },
  {
    path: "/campaign/Travel",
    element: <ProtectedRoute element={<CampaignTravel />} />,
  },
  {
    path: "/Travel",
    element: <ProtectedRoute element={<Travel />} />,
  },

  {
    path: "/Other",
    element: <ProtectedRoute element={<Other />} />,
  },
  {
    path: "/qr",
    element: <ProtectedRoute element={<Qr />} />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={<DashBoard />} />,
  },
  {
    path: "/agent/dashboard",
    element: <ProtectedRoute element={<AgentDashBoard />} />,
  },

  {
    path: "/faq",
    element: <ProtectedRoute element={<Faq />} />,
  },
  {
    path: "/Pricing",
    element: <ProtectedRoute element={<PricingPlans />} />,
  },
  {
    path: "/bill-details/:id",
    element: <ProtectedRoute element={<BillDetails />} />,
  },
  {
    path: "/checkout/",
    element: <ProtectedRoute element={<CheckOut />} />,
  },
  {
    path: "/checkout/:id",
    element: <ProtectedRoute element={<ChashfreeRes />} />,
  },
  {
    path: "/Call-Log",
    element: <ProtectedRoute element={<CallLogs />} />,
  },
  {
    path: "/all-tickets",
    element: <ProtectedRoute element={<TicketTable />} />,
  },
  {
    path: "/single-ticket:id",
    element: <ProtectedRoute element={<SingleTicket />} />,
  },
  {
    path: "/single-ticket/:id",
    element: <SingleTicket />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/agent/login",
    element: <AgentLogin />,
  },
  {
    path: "/agent/all-ticket",
    element: <AgentTickets />,
  },
  {
    path: "/agent/single-ticket/:id",
    element: <SIngleTicket />,
  },

  {
    path: "/otp",
    element: <Otp />,
  },
]);

export default router;
