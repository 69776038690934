import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  useMediaQuery,
  useTheme,
  tableCellClasses,
  Grid,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CreateTicket from "./CreateTicket";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import TicketCards from "./Card";
import { styled } from "@mui/material/styles";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getStatusBgColor = (status) => {
  switch (status?.toLowerCase()) {
    case "open":
      return "green"; // Light cyan for open status
    case "in progress":
      return "orange"; // Light orange for in progress
    case "closed":
      return "red"; // Light green for closed status
    case "waiting on customer":
      return "orange"; // Light pink for waiting on customer
    default:
      return "blue"; // Default light grey
  }
};

const TicketTable = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState({});

  useEffect(() => {
    getAllTicket();
  }, []);

  const getAllTicket = async () => {
    try {
      const res = await axios.get(`${apiEndPointsConfig.ticketList}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setData(res?.data?.data?.TicketLists || []);
      setCardDetails(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const handleDelete = (row) => {
    console.log("Delete action for", row);
  };

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: 900,
            color: "#424242",
          }}
          gutterBottom
        >
          All Tickets
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "16px",
          pb: "30px",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={10}>
          <TicketCards cardDetails={cardDetails} />
        </Grid>
        <Grid item xs={12} md={2}>
          <CreateTicket
            getAllTicket={getAllTicket}
            open={open}
            setOpen={setOpen}
          />
        </Grid>
      </Grid>

      {data?.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>No Tickets Found</Typography>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid #EEEEEE",
            borderRadius: 4,
            overflowX: "auto",
          }}
        >
          <Table
            sx={{
              minWidth: "700px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Ticket ID</StyledTableCell>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Company Name</StyledTableCell>
                <StyledTableCell>Assignee</StyledTableCell>
                <StyledTableCell>Mobile</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Ticket Type</StyledTableCell>
                <StyledTableCell>Create Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    onClick={() =>
                      navigate(`/agent/single-ticket/${row.TicketID}`)
                    }
                    sx={{
                      color: "darkblue",
                      cursor: "pointer",
                    }}
                  >
                    {row.TicketID}
                  </StyledTableCell>
                  <StyledTableCell>{row.CustomerName}</StyledTableCell>
                  <StyledTableCell>{row.businessName}</StyledTableCell>
                  <StyledTableCell>{row.Assignee}</StyledTableCell>
                  <StyledTableCell>{row.Mobile}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: getStatusBgColor(row.Status),
                    }}
                  >
                    {row.Status}
                  </StyledTableCell>
                  <StyledTableCell>{row.TicketType}</StyledTableCell>
                  <StyledTableCell>{row.CreateDate}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TicketTable;
