import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import JusttawkLogo from "../../../../src/assets/Justtawk-01.png";
import LoginBg from "../../../../src/assets/LoginBg.jpeg";
import JusttawkBg from "../../../../src/assets/justtawk-bg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";
import ImgBook from "../../../../src/assets/img_book_english.png";
import Certification1 from "../../../../src/assets/certification-01.jpg";
import Certification2 from "../../../../src/assets/certification-02.jpg";
import Certification3 from "../../../../src/assets/certification-03.jpg";
import Certification4 from "../../../../src/assets/certification-04.jpg";
import { CheckCircle } from "@mui/icons-material";

export default function GetStarted() {
  const navigate = useNavigate();
  const [ipAddress, setIpAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  console.log(errorMessage, "errorMessage");

  useEffect(() => {
    addIpAddress();
  }, []);

  const addIpAddress = async () => {
    const res = await axios.get(`https://api64.ipify.org/?format=json`);
    setIpAddress(res.data.ip);
  };

  const registerUser = async (value) => {
    const data = {
      name: value?.name,
      email: value?.email,
      companyName: value?.companyName,
      mobile: value?.mobile,
      ip_address: ipAddress,
    };

    try {
      const response = await axios.post(
        `https://uae.justtawk.com/api/UserRegistration/register`,
        data,
        {
          headers: {
            Authorization: `lpuSHSpG9itZvnFgwCQbzYiAovutZLZrmaJ8k_2MuOM`,
          },
        }
      );
      console.log(value?.mobile, "res");
      localStorage.setItem("token", response?.data?.access_token);
      localStorage.setItem("uid", response?.data?.data?.uesrid);
      localStorage.setItem("mobileNo", value?.mobile);
      if (response.data.status === true) {
        navigate("/campaign/faq-selection");
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      console.error("Error:", error.response);
    }
  };

  const location = useLocation();

  const getQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  };

  useEffect(() => {
    const utm_source = getQueryParam("utm_source");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_medium = getQueryParam("utm_medium");
    if (utm_source) {
      localStorage.setItem("source", utm_source);
    }
    if (utm_medium) {
      localStorage.setItem("medium", utm_medium);
    }
    if (utm_campaign) {
      localStorage.setItem("campaign", utm_campaign);
    }
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      mobile: "",
      name: "",
      companyName: "",
      email: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number"),
      name: Yup.string().required("Name is required"),
      companyName: Yup.string().required("Company Name is required"),
      email: Yup.string().required("Email is required"),
    }),
    onSubmit: (values) => {
      registerUser(values);
    },
  });

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            mt: 0,
            height: {
              xs: "100%",
              md: "100vh",
            },
            overflow: "hidden",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              height: {
                xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
                md: "100vh", // Full height on larger screens
              },
              backgroundImage: {
                xs: `url(${JusttawkBg})`,
                md: `url(${LoginBg})`,
              },
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) => t.palette.primary.main,
              backgroundSize: "cover", // Ensures the image covers the entire area
              backgroundPosition: "center",
              position: "relative",
              color: "white",
              display: { xs: "flex", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
              mt: {
                xs: "-100px",
                md: "0px",
              },
              //   borderRadius: "18px",
              //   position: "fixed", // Keeps the left side fixed
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 900,
                  },
                  fontSize: {
                    xs: "1.1rem",
                    md: "1.8rem",
                  },
                  mt: {
                    xs: "50px",
                    md: "0px",
                  },
                  whiteSpace: "nowrap",
                }}
              >
                Welcome to{" "}
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                    justifyContent: "center",
                    alignItems: "center",
                    p: "7px 0",
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "220px", // Set a fixed width for the image
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "inline" }, // Show text on md and larger
                  }}
                >
                  <img
                    src={JusttawkLogo}
                    alt="Justtawk Logo"
                    style={{
                      height: "auto", // Keep aspect ratio intact
                      maxWidth: "100%", // Ensure the image does not exceed container width
                      width: "150px", // Set a fixed width for the image
                    }}
                  />
                </Box>
              </Typography>

              <Typography
                sx={{
                  fontWeight: {
                    xs: 400,
                    md: 100,
                  },
                  fontSize: "1rem",
                  p: "0 50px",
                }}
              >
                Affordable call center solution that helps your business by
                seamlessly receiving and managing customer calls.
              </Typography>
            </Box>

            {/* Bottom Text Block */}
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexDirection: "column",
                justifyContent: "center", // Center text vertically
                alignItems: "center", // Center text horizontally
                textAlign: "center", // Ensure text is centered within its Box
                mt: "auto", // Pushes the bottom block towards the bottom
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 900,
                  fontSize: {
                    xs: "1rem",
                    md: "1.8rem",
                  },
                }}
              >
                Your business, our voice.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 100,
                  fontSize: {
                    xs: "0.7rem",
                    md: "1rem",
                  },
                }}
              >
                Real-time call monitoring and 24/7 support for <br /> seamless
                customer service.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{
              p: { xs: "0 40px", md: "0 50px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              maxWidth: "500px",
              mx: "auto",
              height: "100%",
              overflowY: "scroll", // Makes the right side scrollable
            }}
          >
            <Box
              sx={{
                mt: {
                  xs: "0px",
                  md: "1200px",
                },
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      md: 450,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2rem",
                      fontWeight: 900,
                      textAlign: {
                        xs: "center",
                        md: "initial",
                      },
                      mt: {
                        xs: "30px",
                        md: "0px",
                      },
                    }}
                  >
                    Hey! Good Choice.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      display: {
                        xs: "none",
                        md: "initial",
                      },
                    }}
                  >
                    it's free. No credit card needed.
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: "#26203B",
                        mt: 3,
                        mb: 1,
                      }}
                    >
                      Please enter your mobile number*
                    </Typography>
                    <TextField
                      id="mobile"
                      name="mobile"
                      type="tel"
                      variant="outlined"
                      fullWidth
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #465FF166",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: "#26203B",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      Please enter your name*
                    </Typography>
                    <TextField
                      id="name"
                      name="name"
                      variant="outlined"
                      fullWidth
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #465FF166",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: "#26203B",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      Please enter your email*
                    </Typography>
                    <TextField
                      id="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #465FF166",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: "#26203B",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      Please enter your company name*
                    </Typography>
                    <TextField
                      id="companyName"
                      name="companyName"
                      variant="outlined"
                      fullWidth
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.companyName &&
                        Boolean(formik.errors.companyName)
                      }
                      helperText={
                        formik.touched.companyName && formik.errors.companyName
                      }
                      InputProps={{
                        style: {
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #465FF166",
                        },
                      }}
                    />
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: "#3652E2",
                      textTransform: "capitalize",
                    }}
                  >
                    Get Register
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {errorMessage == false ? (
                    ""
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          bgcolor: "#ff8787",
                          borderRadius: 1,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          // width: {
                          //   xs: "95%",
                          //   md: 430,
                          // },
                        }}
                      >
                        <CheckCircle
                          sx={{
                            color: "red",
                            fontSize: "medium",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "small",
                          }}
                        >
                          {errorMessage}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </form>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.8rem",
                    // color: "#465FF1",
                    // cursor: "pointer",
                    mt: 1,
                  }}
                >
                  Already have an account?{" "}
                  <span
                    style={{
                      color: "#465FF1",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                //   height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                px: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: "#1A5CB0",
                  mb: 2,
                  mt: "100px",
                }}
              >
                Launch an AI-based Call Center Solution in Just 5 Minutes*
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  color: "#4a4a4a",
                  lineHeight: 1.6,
                  mb: 2,
                }}
              >
                - 24/7 Support and Monitoring <br />
                - Free Dialer and Connectivity <br />
                - Multi-language Support <br />- Free CRM
              </Typography>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: "#1A5CB0",
                    mb: 2,
                    mt: "50px",
                  }}
                >
                  Get Ready to Build Amazing customer experience with six easy
                  steps
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={ImgBook}
                    alt="Book Illustration"
                    style={{
                      maxWidth: "80%", // Make the image responsive
                      height: "auto", // Maintain aspect ratio
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    // border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "20px",
                    maxWidth: "800px",
                    margin: "0 auto",
                    mt: "50px",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      color: "#1A5CB0",
                      textAlign: "left",
                    }}
                  >
                    No hardware required. Instant training support
                  </Typography>
                  <Typography
                    textAlign={"left"}
                    variant="body1"
                    component="h3"
                    paragraph
                  >
                    Get started in 5 minutes*.
                  </Typography>
                  <Typography textAlign={"left"} variant="body1" paragraph>
                    <strong>• Instant set up:</strong> Get started in just 5
                    minutes with our seamless, no-hardware onboarding process.
                  </Typography>
                  <Typography textAlign={"left"} variant="body1" paragraph>
                    <strong>• Affordable pricing:</strong> Budget-friendly plans
                    tailored for businesses of all sizes and needs.
                  </Typography>
                  <Typography textAlign={"left"} variant="body1" paragraph>
                    <strong>• Real-time reporting:</strong> Access actionable
                    insights and performance metrics instantly, anytime,
                    anywhere.
                  </Typography>
                  <Typography textAlign={"left"} variant="body1" paragraph>
                    <strong>• 24*7 support:</strong> Round-the-clock assistance
                    from our expert support team, always ready to help.
                  </Typography>
                  <Typography textAlign={"left"} variant="body1" paragraph>
                    <strong>• Free CRM:</strong> Streamline customer management
                    with our complimentary, user-friendly CRM software.
                  </Typography>
                  <Typography
                    textAlign={"left"}
                    variant="h6"
                    component="h3"
                    paragraph
                  >
                    Built for teams that want to serve better, faster.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: "50px 0",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="h2"
                    sx={{
                      fontWeight: 700,
                      color: "#1A5CB0",
                      mb: 2,
                    }}
                  >
                    Our Certification
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <img
                      src={Certification1}
                      alt="Certification 1"
                      style={{ width: "50px", height: "auto" }}
                    />

                    <img
                      src={Certification3}
                      alt="Certification 3"
                      style={{ width: "50px", height: "auto" }}
                    />
                    <img
                      src={Certification4}
                      alt="Certification 4"
                      style={{ width: "50px", height: "auto" }}
                    />
                    <img
                      src={Certification2}
                      alt="Certification 2"
                      style={{ width: "50px", height: "auto" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>{" "}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
