import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const pricingPlans = [
  {
    title: "Demo",
    price: "Free",
    hour: "12",
    features: [
      "Super user account",
      "Unlimited events",
      "Registration Form",
      "Email announcements",
      "Integrate webhooks",
      "Sales using mobile app",
    ],
  },
  {
    title: "Business",
    price: 10000,
    hour: "12",
    features: [
      "All Team features",
      "Advanced analytics",
      "Priority support",
      "Custom branding",
      "API access",
      "Dedicated account manager",
    ],
  },
  {
    title: "Enterprise",
    price: 20000,
    hour: "24",
    features: [
      "All Business features",
      "Unlimited users",
      "Tailored solutions",
      "Enhanced security",
      "Onboarding support",
      "Personalized training",
    ],
  },
];
export default function TeamPricingCard() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [cardDetails, setCardDetails] = useState([]);
  console.log(cardDetails, "cardDetails");

  useEffect(() => {
    getPricing();
  }, []);

  const getPricing = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.getSubscriptionPlans}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status == false) {
        localStorage.clear();
      }
      setCardDetails(res?.data?.message);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  return (
    <Box sx={{ p: 3, mt: 2 }}>
      <Grid container spacing={3} justifyContent="center">
        {cardDetails &&
          cardDetails?.map((e, i) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  maxWidth: 400,
                  width: "100%",
                  position: "relative",
                  overflow: "visible",
                  transition: "transform 0.3s ease-in-out", // Add a smooth transition
                  "&:hover": {
                    transform: "scale(1.05)", // Scale up by 5% when hovered
                  },
                  borderRadius: 6,
                  border: "1px solid #EEEEEE",
                }}
              >
                <CardContent sx={{ p: 5 }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontWeight: 900,
                      mb: 2,
                    }}
                  >
                    {e?.title}
                  </Typography>

                  <Box sx={{ textAlign: "center", mb: 0 }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        display: "inline-flex",
                        alignItems: "baseline",
                        color: "lightgray",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹{Math.floor(e?.price)}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "1rem",
                        ml: 1,
                        color: "lightgray",
                      }}
                    >
                      /mo
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "center", mb: 1 }}>
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        display: "inline-flex",
                        alignItems: "baseline",
                      }}
                    >
                      ₹{Math.floor(e?.offer_price)}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "1rem",
                        ml: 1,
                        color: "text.secondary",
                      }}
                    >
                      /mo
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "center", mb: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{
                        // fontWeight: "bold",
                        display: "inline-flex",
                        alignItems: "baseline",
                        textTransform: "capitalize",
                        color: "black",
                        border: "1px solid #b0b0b0",
                      }}
                    >
                      {e?.window}
                    </Button>
                  </Box>

                  <List sx={{ mt: 0 }}>
                    {e?.features.map((feature, idx) => (
                      <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <CheckIcon sx={{ color: "green" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={feature.title}
                          sx={{
                            "& .MuiListItemText-primary": {
                              fontSize: "0.95rem",
                              color: "text.secondary",
                              fontWeight: 600,
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      m: "10px 0",
                      py: 1.5,
                      textTransform: "none",
                      borderRadius: 2,
                      backgroundColor: "#1a237e",
                      "&:hover": {
                        backgroundColor: "#0d47a1",
                      },
                    }}
                    onClick={() => navigate(`/bill-details/${e?.sid}`)}
                  >
                    Get started
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
