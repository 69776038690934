import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import SingleTicketCard from "./SingleTicketCard";
import TicketComment from "./TicketComment";
import SideNav from "../../../../agentComponents/sidenav";
import { useFormik } from "formik";
import apiEndPointsConfig from "../../../../services/apiEndPoints";

const SIngleTicket = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [status, setStatus] = useState([]);
  const [ticket, setTicket] = useState([]);
  const agentId = localStorage.getItem("agentId");

  useEffect(() => {
    getSingleTicket();
    getAllStatus();
  }, []);

  const getSingleTicket = async () => {
    const res = await axios.get(`${apiEndPointsConfig.getTicketById}/${id}`, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    });
    setTicket(res.data.data.ticket);
  };

  const getAllStatus = async () => {
    try {
      const res = await axios.get(`${apiEndPointsConfig.getAllStatus}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setStatus(res?.data?.data);
    } catch (error) {}
  };

  const updateStatus = async (statusId) => {
    const data = {
      Status: statusId,
      userid: agentId,
    };
    try {
      const res = await axios.put(
        `${apiEndPointsConfig.changeStatus}/${ticket.TicketID}`,
        data,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      status: ticket.Status || "",
    },
    enableReinitialize: true, // Allows initial values to update dynamically
    onSubmit: (values) => {
      console.log("Submitted values:", values);
    },
  });
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          p: {
            xs: "50px 0 0 0",
            md: "100px 20px",
          },
          bgcolor: "white",
        }}
      >
        <SideNav />
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  mb: 2,
                  color: "#424242",
                }}
              >
                <span
                  style={{
                    fontWeight: 900,
                  }}
                >
                  Ticket Number
                </span>{" "}
                {ticket.TicketID}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  mb: 2,
                  ml: "50px",
                  color: "#424242",
                }}
              >
                <span
                  style={{
                    fontWeight: 900,
                  }}
                >
                  Call Time
                </span>{" "}
                {ticket.CreateDate}
              </Typography>
            </Box>

            <Box>
              <Autocomplete
                disableClearable
                name="status"
                id="status"
                options={status}
                getOptionLabel={(option) => option.name || ""}
                value={
                  status.find((s) => s.name === formik.values.status) || null
                }
                onChange={(event, value) => {
                  console.log(value, "valueeeee");
                  formik.setFieldValue("status", value?.name || "");
                  updateStatus(value.id);

                  formik.submitForm();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    sx={{
                      width: 250,
                      "& .MuiOutlinedInput-root": {
                        padding: "10px",
                        height: "40px",
                        borderRadius: "8px",
                        "&.Mui-focused": {
                          borderColor: "#1976d2",
                        },
                      },
                    }}
                    error={Boolean(
                      formik.errors.status && formik.touched.status
                    )}
                    helperText={formik.touched.status && formik.errors.status}
                  />
                )}
              />
            </Box>
          </Box>

          <SingleTicketCard ticket={ticket} />
          <Box
            sx={{
              bgcolor: "white",

              overflowX: "auto",
              mt: 4,
            }}
          >
            <TableContainer>
              <Table
                sx={{
                  minWidth: 650,
                  bgcolor: "white", // Background color for the table
                  borderCollapse: "collapse", // Ensure borders collapse
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "1px solid grey" }}>ID</TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      Customer
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      TicketType
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      CreateDate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={ticket.TicketID}>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.TicketID}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.CustomerName}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.Title}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.Description}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.Status}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.TicketType}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {ticket.CreateDate}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <TicketComment ticket={ticket} />
        </Box>
      </Box>
    </div>
  );
};

export default SIngleTicket;
