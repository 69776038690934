import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../../assets/Group.png";
import ManualFaqByAiMobile from "./ManualFaqByAiMobile";
import axios from "axios";
import JusttawkBg from "../../../../assets/justtawk-bg.png";
import JusttawkLogo from "../../../../assets/Justtawk-01.png";
import lightbulb from "../../../../assets/lightbulb.png";
import yelloBulb from "../../../../assets/lightbulb1.png";
import apiEndPointsConfig from "../../../../services/apiEndPoints";

const validationSchema = Yup.object({
  summary: Yup.string()
    .required("Field is required")
    .test("word-count", "Summary must be at least 80 words", (value) => {
      if (!value) return false; // Ensure the field is not empty
      const wordCount = value.trim().split(/\s+/).length; // Count words
      return wordCount >= 80;
    }),
});

const ManualFaqByAi = ({ nextStep }) => {
  const navigate = useNavigate();

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const industryType = localStorage.getItem("industryType");

  const addFaqDetails = async (data) => {
    const access_token = token;
    try {
      const response = await axios.put(
        `${apiEndPointsConfig.addFaqByAi}/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/add-manual-faq-by-ai");
      localStorage.removeItem("industryType");
      console.log("Response:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      summary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        summary: values.summary,
        industryType: industryType,
      };
      console.log(data);
      addFaqDetails(data);
    },
  });

  return (
    <Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            height: {
              xs: "50vh", // Use 50vh for mobile, it ensures it's more flexible
              md: "100vh", // Full height on larger screens
            },
            backgroundImage: {
              xs: `url(${JusttawkBg})`,
            },
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "20px", md: "100px 0" }, // Adjust padding for mobile view
            mt: {
              xs: "-100px",
              md: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 900,
                },
                fontSize: {
                  xs: "1.1rem",
                  md: "1.8rem",
                },
                mt: {
                  xs: "50px",
                  md: "0px",
                },
                whiteSpace: "nowrap",
              }}
            >
              Welcome to{" "}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" }, // Show image on mobile, hide on md and larger
                  justifyContent: "center",
                  alignItems: "center",
                  p: "7px 0",
                }}
              >
                <img
                  src={JusttawkLogo}
                  alt="Justtawk Logo"
                  style={{
                    height: "auto", // Keep aspect ratio intact
                    maxWidth: "100%", // Ensure the image does not exceed container width
                    width: "220px", // Set a fixed width for the image
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "inline" }, // Show text on md and larger
                }}
              >
                Justtawk
              </Box>
            </Typography>

            <Typography
              sx={{
                fontWeight: {
                  xs: 400,
                  md: 100,
                },
                fontSize: "1rem",
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>
        </Grid>
        <Typography
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            justifyContent: "center",
            p: "20px 0",
          }}
        >
          Step 2 - Define Frequently Ask Questions
        </Typography>
        <Grid container>
          <Grid
            item
            md={9.5}
            xs={12}
            sx={{
              height: {
                xs: "100%",
                md: "100vh",
              },
            }}
          >
            <Box
              sx={{
                p: "5px 10px",
              }}
            >
              <Box
                sx={{
                  height: "8vh",
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <img src={Logo} alt="Logo" height="100%" width="200" />{" "}
              </Box>
            </Box>
            <Container maxWidth={"md"}>
              <Box
                sx={{
                  padding: {
                    xs: "0px 0px 10px 20px",
                    md: "90px 0px 0 0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.6rem",
                      md: "2.5rem",
                    },
                    fontWeight: 900,

                    lineHeight: {
                      xs: "35px",
                    },
                  }}
                >
                  Build AI-powered dynamic intelligent questionnaires.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.2rem",
                      md: "1.4rem",
                    },
                    fontWeight: 10,
                    lineHeight: {
                      md: "25px",
                    },
                    color: "#6D6D6D",
                    mt: "15px",
                    textAlign: {
                      xs: "center",
                      md: "initial  ",
                    },
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  Please help us with a short write up about yourself and your
                  business so that we can tailor the FAQs as per your
                  requirement.
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  sx={{
                    mt: {
                      xs: "20px",
                      md: "80px",
                    },
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <Grid item xs={12} md={9.5}>
                    <Box>
                      <Box
                        sx={{
                          height: {
                            xs: "30vh",
                            md: "40vh",
                          },
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            width: {
                              xs: "100%",
                              md: "100%",
                              p: "00px 0 100px 0",
                            },
                          }}
                        >
                          <TextField
                            name="summary"
                            id="summary"
                            variant="outlined"
                            margin="normal"
                            label="Enter company details (timings, industry, fieldwork, etc.)"
                            fullWidth
                            rows={5}
                            multiline
                            value={formik.values.summary}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.summary &&
                              Boolean(formik.errors.summary)
                            }
                            helperText={
                              formik.touched.summary && formik.errors.summary
                                ? formik.errors.summary
                                : ""
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      ml: {
                        xs: "0px",
                        md: "30px",
                      },
                      p: {
                        xs: "20px 0 50px 0",
                        md: "0 0 0px 0",
                      },
                      display: {
                        xs: "flex",
                        md: "initial",
                      },
                      // justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        mt: {
                          xs: "0px",
                          md: "15px",
                        },
                        ml: {
                          xs: "30px",
                          md: "0",
                        },
                      }}
                    >
                      Save & go next
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Box
                sx={{
                  display: {
                    xs: "initial",
                    md: "none",
                  },
                }}
              >
                <ManualFaqByAiMobile />
              </Box>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              background: "#1A5CB0",
              padding: "0 0px 50px 0",
              display: {
                xs: "none",
                md: "flex",
              },
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                color: "white",
                fontWeight: 900,
                p: "100px 20px 40px 20px",
              }}
            >
              Quick & Easy Setup
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stepper
                activeStep={1}
                orientation="vertical"
                sx={{
                  color: "white",
                  "& .MuiStepLabel-label": {
                    color: "white",
                  },
                }}
              >
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Business Details
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Define Frequently Ask Questions
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Select Customer Care Number
                    </Typography>
                  </StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManualFaqByAi;
