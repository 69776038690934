import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { PieChart, CallMade } from "@mui/icons-material";
import { Phone } from "lucide-react";
import Timmer from "./Timmer";

export default function WelcomeSection({ data }) {
  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Grid container spacing={2}>
        {/* Welcome Text */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              borderRadius: 6,
              border: "1px solid #EEEEEE",
            }}
          >
            <Typography variant="body1" sx={{ p: 2 }}>
              <Box
                component="span"
                sx={{ color: "text.secondary", fontWeight: 900 }}
              >
                Welcome{" "}
              </Box>
              <Box component="span" sx={{ color: "orange", fontWeight: 900 }}>
                {data?.users?.users_business_name}!
              </Box>
            </Typography>
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                // p: "2.55px 0 0px 0",
              }}
            ></Box>
            <Typography
              sx={{ p: 2, color: "text.secondary", fontSize: "small" }}
            >
              Here's Your Customer Care Number :{" "}
              <Box component="span" sx={{ color: "#1a237e" }}>
                {data?.users?.did_number}
              </Box>
            </Typography>
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                // p: "5px 0 0px 0",
              }}
            ></Box>
            <Typography
              sx={{ p: 2, color: "text.secondary", fontSize: "small" }}
            >
              <Timmer />
            </Typography>
          </Box>
        </Grid>

        {/* Assignee Section */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              borderRadius: 6,
              border: "1px solid #EEEEEE",
              p: 3,
            }}
          >
            {/* Header */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 2,
                  // bgcolor: "#f0f0ff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                <PieChart sx={{ color: "#6366f1" }} />
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 550, flexGrow: 1 }}>
                Assignee
              </Typography>
            </Box>

            {/* Metrics */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 3,
              }}
            >
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  All Tickets
                </Typography>
                <Typography sx={{ fontWeight: "small" }}>
                  {data?.ticketAssigneeStatus?.Total}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  Agent
                </Typography>
                <Typography sx={{ fontWeight: "small" }}>
                  {data?.ticketAssigneeStatus?.Agents}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  Clients
                </Typography>
                <Typography sx={{ fontWeight: "small" }}>
                  {" "}
                  {data?.ticketAssigneeStatus?.Client}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Call Details Section */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              borderRadius: 6,
              border: "1px solid #EEEEEE",
              p: 3,
            }}
          >
            {/* Header */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 2,
                  // bgcolor: "#f0f0ff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                <Phone size={20} style={{ color: "#6366f1" }} />
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 550, flexGrow: 1 }}>
                Call Details
              </Typography>
            </Box>

            {/* Metrics */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 3,
              }}
            >
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  All Calls
                </Typography>
                <Typography sx={{ fontWeight: "small" }}>
                  {" "}
                  {data?.calls?.total}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small", whiteSpace: "nowrap" }}
                >
                  Connected Call
                </Typography>
                <Typography sx={{ fontWeight: "small", color: "green" }}>
                  {" "}
                  {data?.calls?.Answered_Call}
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="text.secondary"
                  sx={{ mb: 1, fontSize: "small" }}
                >
                  Missed Call
                </Typography>
                <Typography sx={{ fontWeight: "small", color: "red" }}>
                  {data?.calls?.Missed_Call}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
