import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Abc, ShoppingBag, ShoppingCart } from "@mui/icons-material";
import Person3Icon from "@mui/icons-material/Person3";
import FolderIcon from "@mui/icons-material/Folder";

export default function SingleTicketCard({ ticket }) {
  console.log(ticket, "ticket");

  return (
    <Box>
      <Grid>
        {/* <Box
          sx={{
            margin: 2,
            width: "100%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              padding: 2,
            }}
          >
            {ticket.CallUrl && (
              <audio
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                src={ticket.CallUrl}
                controls
              />
            )}
          </Box>
        </Box> */}
        <Box
          sx={{
            bgcolor: "white",

            overflowX: "auto",
            mt: 1,
          }}
        >
          <Typography sx={{ p: 1 }}>Ticket Details</Typography>
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
                bgcolor: "white", // Background color for the table
                borderCollapse: "collapse", // Ensure borders collapse
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Name : {ticket.CustomerName}
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Email : {ticket.Email}
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Mobile no : {ticket.Mobile}
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Title : {ticket.Title}
                  </TableCell>

                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Description : {ticket.Description}
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    Status : {ticket.Status}
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    CreateDate
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    1231231
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    abhay
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    lafda
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    ho gyaa
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    auto
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    bug
                  </TableCell>
                  <TableCell
                    sx={{ border: "8px solid white", bgcolor: "#F4F5FA" }}
                  >
                    today
                  </TableCell>
                </TableRow>
              </TableBody> */}
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Box>
  );
}
