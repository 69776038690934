const apiEndPointsConfig = {
  // user //

  //########  Login Details ######### //
  addLogin: "https://justtawk.info/api/User/register",
  otpVarification: "https://justtawk.info/api/User/otp",

  //########  Company Details ######### //
  companyDetails: "https://justtawk.info/api/Onboarding/Register",
  companyFaqs: "https://justtawk.info/api/Onboarding/Faq",
  getAllNumbers: "https://justtawk.info/api/Onboarding/FetchNumber",
  customerCareNumber: "https://justtawk.info/api/Onboarding/CustomerCareNumber",
  addFaqByAi: "https://justtawk.info/api/User/companySummary",
  getFaqByAi: "https://justtawk.info/api/User/FaqByAI",

  //########  Dasboard  ######### //
  onboardingDashboard: "https://justtawk.info/api/Onboarding/Dashboard",
  getAllFaq: "https://justtawk.info/api/Onboarding/FaqByUser",
  addFaqByImg: "https://justtawk.info/api/Onboarding/FaqImage",
  editFaq: "https://justtawk.info/api/Onboarding/Faq",
  getCallLogs: "https://justtawk.info/api/Onboarding/CallLogs",
  getSubscriptionPlans: "https://justtawk.info/api/Payment/getSubscriptionPlan",
  getSubscriptionPlanById:
    "https://justtawk.info/api/Payment/getSubscriptionPlanDetailbyID",
  getSubscriptionResponse:
    "https://justtawk.info/api/Payment/SavePaymentResponse",

  /// user ticket
  userSingleTicket: "https://justtawk.info/api/UserTicket/getTicketById",
  userGetStatus: "https://justtawk.info/api/Agents/getStatus",
  changeStatusByUser: "https://justtawk.info/api/UserTicket/changeStatus",
  getTicketByUser: "https://justtawk.info/api/UserTicket/getTicketById",
  deletCommentByUser: "https://justtawk.info/api/UserTicket/deleteComment",
  editCommentByUser: "https://justtawk.info/api/UserTicket/editComment",
  addCommentByUser: "https://justtawk.info/api/UserTicket/AddComment",
  getCustomerTicketByUser:
    "https://justtawk.info/api/UserTicket/getAllCustomerTicket",

  // agent //
  loginAgent: "https://justtawk.info/api/Agents/Login/",
  searchByNumber: "https://justtawk.info/api/Agents/searchByNumber",
  searchByDID: "https://justtawk.info/api/Agents/searchByDID",
  saveWebHookData: "https://justtawk.info/api/Agents/saveWebHookData",

  //agent ticket
  searchByDID: "https://justtawk.info/api/Agents/searchByDID",
  SaveTicket: "https://justtawk.info/api/Agents/SaveTicket",
  getCategory: "https://justtawk.info/api/Agents/getCategory",
  getStatus: "https://justtawk.info/api/Agents/getStatus",
  ticketList: "https://justtawk.info/api/Agents/ticketList",
  getTicketById: "https://justtawk.info/api/UserTicket/getTicketById",
  getAllStatus: "https://justtawk.info/api/Agents/getStatus",
  changeStatus: "https://justtawk.info/api/Agents/changeStatus",

  //agent comments
  getCommentByAgent: "https://justtawk.info/api/Agents/getTicketById",
  deleteCommentByAgent: "https://justtawk.info/api/Agents/deleteComment",
  editCommentByAgent: "https://justtawk.info/api/Agents/editComment",
  addCommentByAgent: "https://justtawk.info/api/Agents/AddComment/",
};

export default apiEndPointsConfig;
