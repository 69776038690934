import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  IconButton,
  Menu,
  Avatar,
  MenuItem,
} from "@mui/material";
import SideNavbar from "../../../components/sideNavbar";
import axios from "axios";
import Timmer from "./Timmer";
import apiEndPointsConfig from "../../../services/apiEndPoints";
import WelcomeSection from "./WelcomeSection";
import CustomerQueries from "./CustomerQueries";
import Header from "../../../components/header";

function DashBoard() {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.onboardingDashboard}/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(res?.data?.data);
      localStorage.setItem("username", res.data.data.users.users_name);
      localStorage.setItem("selectedPhone", res.data.data.users.did_number);
      localStorage.setItem("startDate", res.data.data.users.users_createDate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          ml: { xs: "0px", md: "280px" },
        }}
      >
        <SideNavbar data={data} />
        <Box
          sx={{
            display: {
              xs: "none",
              md: "initial",
            },
          }}
        >
          <Header title={"Dashboard"} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pl: 3,
            pr: 3,
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            // sx={{ mb: 2 }}
          >
            {/* Dashboard Title */}
            <Grid item xs={12} md="auto" sx={{ display: "flex" }}>
              {/* <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  mb: 1,
                }}
              >
                Dashboard
              </Typography> */}
            </Grid>

            {/* Timer */}
            {/* <Grid item xs={12} md="auto">
              <Timmer />
            </Grid> */}
          </Grid>

          <WelcomeSection data={data} />
          <CustomerQueries data={data} />
        </Box>
      </Box>
    </Box>
  );
}

export default DashBoard;
