import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import {
  Button,
  Container,
  Paper,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiEndPointsConfig from "../../../services/apiEndPoints";
import Header from "../../../components/header";
import SideNavbar from "../../../components/sideNavbar";

function BillDetails() {
  const { id } = useParams();
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const [cardDetails, setCardDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPriceDetails();
  }, []);

  const getPriceDetails = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.getSubscriptionPlanById}/${id}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCardDetails(res?.data?.message);
    } catch (error) {
      return error;
    }
  };

  const placeOrder = async () => {
    try {
      const data = {
        sid: id,
        uid: uuid,
        title: cardDetails?.title,
        price: cardDetails?.price,
        offer_price: cardDetails?.offer_price,
        totalAmount: cardDetails?.totalAmount,
        gst: cardDetails?.gst,
        qty: 1,
        language: cardDetails?.language,
        window: cardDetails?.window,
      };
      const res = await axios.post(
        `https://uae.justtawk.com/api/Payment/PlaceOrder`,
        data,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem(
        "paymentSessionId",
        res?.data?.message.paymentSessionId
      );
      localStorage.setItem("orderAmount", res?.data?.message.orderAmount);
      localStorage.setItem("cf_order_id", res?.data?.message.cf_order_id);
      localStorage.setItem("order_id", res?.data?.message.order_id);
      if (res?.data?.status == true) {
        navigate(`/checkout`);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Box>
      <Box
        component="main"
        sx={{
          ml: { xs: "0px", md: "280px" },
        }}
      >
        <SideNavbar />
        <Header title={"Bill Details"} />
        <Box
          sx={{
            p: 3,
          }}
        >
          <Box>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 3, bgcolor: "grey.50" }}>
                  <Typography variant="h5" gutterBottom>
                    Your Order
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="subtitle1" color="text.secondary">
                        {cardDetails?.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        TOTAL
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Typography>
                        ({cardDetails?.language} - {cardDetails?.window} - real
                        agent)
                      </Typography>
                      <Typography>
                        ₹{Math.floor(cardDetails?.offer_price)}
                      </Typography>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Typography>Tax</Typography>
                      <Typography>
                        ₹{Math.floor(cardDetails?.gstAmount)}(18% GST)
                      </Typography>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="h6">ORDER TOTAL</Typography>
                      <Typography variant="h6">
                        ₹{Math.floor(cardDetails?.totalAmount)}
                      </Typography>
                    </Grid>

                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 3,
                        bgcolor: "#e67e22",
                        "&:hover": {
                          bgcolor: "#d35400",
                        },
                      }}
                      onClick={() => placeOrder()}
                    >
                      Pay Now
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BillDetails;
