import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import apiEndPointsConfig from "../../../services/apiEndPoints";

const TicketComment = ({ ticket }) => {
  console.log(ticket, "ticket");
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentComment, setCurrentComment] = useState(null);
  const { id } = useParams();
  const [commentId, setCommentId] = useState("");

  useEffect(() => {
    getComment();
  }, []);

  const getComment = async () => {
    try {
      const res = await axios.get(
        `${apiEndPointsConfig.getTicketByUser}/${id}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setComments(res.data.data.comment);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const res = await axios.delete(
        `${apiEndPointsConfig.deletCommentByUser}/${commentId}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      getComment();
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleModalOpen = () => {
    setEditMode(false);
    setOpen(true);
    setCurrentComment(null);
  };

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleEditClick = (comment) => {
    setCommentId(comment.ID);
    setEditMode(true);
    setCurrentComment(comment);
    setOpen(true);
    formik.setValues({ comment: comment.comments });
  };

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string()
        .required("Comment is required")
        .min(5, "Comment must be at least 5 characters long"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (editMode && currentComment) {
          await axios.put(
            `${apiEndPointsConfig.editCommentByUser}/${commentId}`,
            { comment: values.comment },
            {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          resetForm();
          getComment();
        } else {
          // Add new comment
          const data = {
            ticketID: ticket.TicketID,
            comment: values.comment,
            userid: uid,
          };
          await axios.post(apiEndPointsConfig.addCommentByUser, data, {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          });
        }
        resetForm();
        getComment();
        handleModalClose();
      } catch (error) {
        console.error("Error submitting comment:", error);
      }
    },
  });

  return (
    <Box
      sx={{
        bgcolor: "white",
        p: 2,
        borderRadius: "8px",
        mt: 4,
        border: "1px solid lightgrey",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Comments</Typography>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          onClick={handleModalOpen}
        >
          Add Comment
        </Button>
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #e3e3e3",
          mt: 2,
        }}
      />
      {comments.map((e, i) => (
        <Box
          key={i}
          sx={{
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
              color: "black",
            }}
          >
            User Name: {e?.userCommentBy}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "grey",
            }}
          >
            {e?.comments}
          </Typography>
          <Box>
            <Button onClick={() => deleteComment(e.ID)}>
              <DeleteIcon
                sx={{
                  fontSize: 20,
                  color: "#707070",
                }}
              />
            </Button>
            <Button onClick={() => handleEditClick(e)}>
              <ModeTwoToneIcon
                sx={{
                  fontSize: 20,
                  color: "#707070",
                }}
              />
            </Button>
          </Box>
        </Box>
      ))}

      {/* Modal for Adding/Editing Comment */}
      <Modal open={open} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
            minWidth: "400px",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {editMode ? "Edit Comment" : "Add Comment"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="comment"
              name="comment"
              label="Comment"
              variant="outlined"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              sx={{ mb: 2 }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleModalClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {editMode ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default TicketComment;
