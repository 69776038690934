import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
  Menu as MuiMenu,
  MenuItem,
} from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Menu,
  LayoutDashboard,
  Settings,
  User,
  Puzzle,
  ChevronDown,
  FileVolume2,
  Tickets,
  BadgeIndianRupee,
  PhoneCall,
} from "lucide-react";
import ProfileImg from "../../src/assets/user.png";
import Logo from "../../src/assets/just-talk-logo.png";
const DRAWER_WIDTH = 280;

export default function SideNavbar({ data }) {
  const name = localStorage.getItem("username");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [workspace, setWorkspace] = useState(name);
  const [anchorEl, setAnchorEl] = useState(null); // For avatar menu
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };

  const navigationItems = [
    {
      text: "Dashboard",
      icon: <LayoutDashboard size={20} />,
      path: "/dashboard",
    },
    { text: "Solution FAQ’s", icon: <FileVolume2 size={20} />, path: "/faq" },
    {
      text: "Call Log",
      icon: <PhoneCall size={20} />,
      path: "/call-log",
    },
    { text: "Pricing", icon: <BadgeIndianRupee size={20} />, path: "/pricing" },
    { text: "Tickets", icon: <Tickets size={20} />, path: "/all-tickets" },
    // { text: "Profile", icon: <User size={20} />, path: "/profile" },
  ];

  const drawer = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "white",
        color: "white",
        borderRight: "1px solid #ccc",
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          p: "15px 25px 10px 22px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          style={{ height: 40, mixBlendMode: "hard" }}
          alt="Logo"
        />
      </Box>

      {/* Workspace Selector */}
      <Box sx={{ px: 3, mb: 3 }}>
        <Button
          fullWidth
          // endIcon={<ChevronDown size={20} />}
          sx={{
            justifyContent: "space-between",
            color: "white",
            bgcolor: "rgba(255, 255, 255, 0.04)",
            borderRadius: 3,
            border: "1px solid #4B4B4B",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
          }}
        >
          <Box sx={{ textAlign: "left", p: "1px 5px" }}>
            <Typography
              variant="body2"
              color="rgba(22, 22, 22, 0.7)"
              sx={{
                textTransform: "capitalize",
                fontWeight: 100,
              }}
            >
              {workspace || data?.users?.users_name}
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          borderTop: "0.5px solid #ccc",
          p: "5px 0 0px 0",
        }}
      ></Box>

      {/* Navigation Items */}
      <List sx={{ px: 2, flex: 1 }}>
        {navigationItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={NavLink}
              to={item.path}
              sx={{
                borderRadius: 2,
                "&.active": {
                  bgcolor: "rgb(99, 102, 241)",
                  "& .MuiListItemText-primary": {
                    color: "white",
                    fontWeight: 600,
                  },
                  "& .MuiListItemIcon-root": {
                    color: "white",
                  },
                  "&:hover": {
                    bgcolor: "rgb(99, 102, 241)",
                  },
                },
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.04)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: "grey",
                  minWidth: 40,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.875rem",
                    fontWeight: 100,
                    color: "black",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ p: 3, textAlign: "" }}>
        <Typography variant="h6" sx={{ mb: 1, color: "black" }}>
          Need more services?
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            color: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <Link
            to="/pricing"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            Choose from our affordable packages
          </Link>
        </Typography>
        <Box
          component="img"
          src="https://material-kit-react.devias.io/assets/devias-kit-pro.png"
          alt="Pro features"
          sx={{
            width: "100%",
            maxWidth: 200,
            height: "auto",
            mx: "auto",
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box>
      {/* Mobile Header */}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "100%",
            borderBottom: "1px solid #eee",
            p: "5px 20px",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <Menu color="black" size={24} />
          </IconButton>
          <Avatar
            src={ProfileImg}
            alt="User Profile"
            sx={{ width: 29, height: 29, cursor: "pointer" }}
            onClick={handleAvatarClick} // Opens the menu
          />
        </Box>
      )}

      {/* Avatar Menu */}
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate("/profile");
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleLogout();
          }}
        >
          Logout
        </MenuItem>
      </MuiMenu>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            bgcolor: "rgb(17, 20, 28)",
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Desktop Permanent Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            bgcolor: "rgb(17, 20, 28)",
            border: "none",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
